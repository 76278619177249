import {
  ACTIVATE_GPON_IS_LOADING,
  RETRIEVE_TAG_IS_LOADING,
  CREATE_ORDER_FORM_VALUES,
  CREATE_ORDER_IS_LOADING,
  CUSTOMER_INFRA_INFO,
  EDIT_ORDER_FORM_VALUES,
  FINALIZE_ORDER_IS_LOADING,
  LOAD_ORDERS_IS_LOADING,
  LOAD_SINGLE_ORDER_IS_LOADING,
  LOCATE_BY_GIS_FORM_VALUES,
  LOCATE_BY_GIS_IS_LOADING,
  LOCATE_CUSTOMER_INFRA_FORM_VALUES,
  LOCATE_CUSTOMER_INFRA_IS_LOADING,
  LOCATED_BOX_INFO,
  ORDERS_KEYWORD,
  ORDERS_TABLE,
  RETRIEVE_ORDER_IS_LOADING,
  SINGLE_ORDER,
  RETRIEVE_TAG_WS14_RESULT,
  RETRIEVED_TAG_INFO,
  REDIRECT_TO_CREATE_CHECK_LINE_IS_LOADING,
  REDIRECT_TO_CREATE_TICKET_IS_LOADING,
  REDIRECT_TO_SHOW_USER_TICKETS_IS_LOADING,
  CANCEL_ORDER_IS_LOADING,
  CLOSE_ORDER_AND_REMOVE_LINE_IS_LOADING
} from 'Containers/Orders/consts'

import {ORDERS} from 'Redux/consts'
import {createSelector} from 'reselect'

export const getLocateCustomerInfraFormValues = state => state.getIn([ORDERS,LOCATE_CUSTOMER_INFRA_FORM_VALUES])?.toJS()

export const getCustomerInfraInfo = state => state.getIn([ORDERS,CUSTOMER_INFRA_INFO])?.toJS()

export const getLocateCustomerInfraIsLoading = state => state.getIn([ORDERS,LOCATE_CUSTOMER_INFRA_IS_LOADING])

export const getEditOrderFormValues = createSelector(
  state => state.getIn([ORDERS,EDIT_ORDER_FORM_VALUES]),
  formValues => formValues?.toJS()
)

export const getCreateOrderFormValues = createSelector(
  state => state.getIn([ORDERS,CREATE_ORDER_FORM_VALUES]),
  formValues => formValues?.toJS()
)

export const getCreateOrderIsLoading = state => state.getIn([ORDERS,CREATE_ORDER_IS_LOADING])

export const getOrdersTable = createSelector(
  state => state.getIn([ORDERS,ORDERS_TABLE]),
  ordersTable => ordersTable?.toJS(),
)
export const getLoadOrdersIsLoading = state => state.getIn([ORDERS,LOAD_ORDERS_IS_LOADING])

export const getSingleOrder = state => state.getIn([ORDERS,SINGLE_ORDER])?.toJS()

export const getLoadSingleOrderIsLoading = state => state.getIn([ORDERS,LOAD_SINGLE_ORDER_IS_LOADING])
export const getRetrieveOrderIsLoading = state => state.getIn([ORDERS,RETRIEVE_ORDER_IS_LOADING])

export const getOrdersKeyword = createSelector(
  state => state.getIn([ORDERS,  ORDERS_KEYWORD]),
  keyword => keyword,
)
export const getFinalizeOrderIsLoading = state => state.getIn([ORDERS, FINALIZE_ORDER_IS_LOADING])
export const getActivateGponIsLoading = state => state.getIn([ORDERS,  ACTIVATE_GPON_IS_LOADING])
export const getRetrieveTagIsLoading = state => state.getIn([ORDERS,  RETRIEVE_TAG_IS_LOADING])



export const getLocateByGisIsLoading = state => state.getIn([ORDERS, LOCATE_BY_GIS_IS_LOADING])
export const getLocateByGisFormValues = createSelector(
  state => state.getIn([ORDERS,LOCATE_BY_GIS_FORM_VALUES]),
  formValues => formValues?.toJS()
)

export const getLocatedBoxInfo = createSelector(
  state => state.getIn([ORDERS, LOCATED_BOX_INFO]),
  locatedBoxInfo => locatedBoxInfo?.toJS()
)

export const getRetrievedTagInfo = createSelector(
    state => state.getIn([ORDERS, RETRIEVED_TAG_INFO]),
    retrievedTagInfo => retrievedTagInfo?.toJS()
)

export const getRedirectToCreateTicketIsLoading = state => state.getIn([ORDERS, REDIRECT_TO_CREATE_TICKET_IS_LOADING])
export const getRedirectToCreateCheckLineIsLoading = state => state.getIn([ORDERS, REDIRECT_TO_CREATE_CHECK_LINE_IS_LOADING])
export const getRedirectToShowUserTicketsIsLoading = state => state.getIn([ORDERS, REDIRECT_TO_SHOW_USER_TICKETS_IS_LOADING])
export const getCancelOrderIsLoading = state => state.getIn([ORDERS, CANCEL_ORDER_IS_LOADING])
export const getCloseOrderAndRemoveLineIsLoading = state => state.getIn([ORDERS, CLOSE_ORDER_AND_REMOVE_LINE_IS_LOADING])
