import {
    IS_USER_EDITOR_OPEN,
    LOAD_TENANT_USERS_IS_LOADING,
    LOAD_TENANT_USERS_RESULT, PUBLISH_CHUNK, RESET_PROGRESS, SAVE_TENANT_USERS_IS_LOADING, SERVER_PROGRESS,
    SET_IS_USER_EDITOR_OPEN,
    SET_LOAD_TENANT_USERS_IS_LOADING, SET_SAVE_TENANT_USERS_IS_LOADING, SET_USER_BEING_EDITED,
    TENANT_USERS,
    USER_BEING_EDITED
} from 'Containers/AdminConsole/consts'
import {fromJS} from "immutable";

const initialState = fromJS({
    [TENANT_USERS]: null,
    [LOAD_TENANT_USERS_IS_LOADING]: false,
    [SAVE_TENANT_USERS_IS_LOADING]: false,
    [IS_USER_EDITOR_OPEN]: false,
    [USER_BEING_EDITED]: null,
    [SERVER_PROGRESS]: fromJS({}),
});

export default function AdminConsoleReducer(state = initialState, action){
    switch (action.type) {
        case LOAD_TENANT_USERS_RESULT:
            return state.set(TENANT_USERS, fromJS(action.result))
        case SET_LOAD_TENANT_USERS_IS_LOADING:
            return state.set(LOAD_TENANT_USERS_IS_LOADING, action.isLoading)
        case SET_SAVE_TENANT_USERS_IS_LOADING:
            return state.set(SAVE_TENANT_USERS_IS_LOADING, action.isLoading)
        case SET_IS_USER_EDITOR_OPEN:
            return state.set(IS_USER_EDITOR_OPEN, action.isOpen)
        case SET_USER_BEING_EDITED:
            return state.set(USER_BEING_EDITED, action.emailAndUser)
        case PUBLISH_CHUNK:
            if (action.chunk===undefined || action.chunk===null) {
                return state.set(SERVER_PROGRESS,fromJS({}))
            }
            const serverProgress = state.get(SERVER_PROGRESS).toJS()
            const commaSections = action.chunk.split(',')
            let _p, _section, _row, _of, msg = ''
            if (commaSections.length) {
                commaSections.forEach(commaSection => {
                    const params = commaSection.split('=')
                    if (params.length>1) {
                        if (params[0]==='p') {
                            _p = params[1]
                        }
                    } else {
                        msg += commaSection
                        const rowOf = commaSection.match(/^(.*)\srow\s(\d+)\sof\s(\d+).*$/)
                        if (rowOf?.length) {
                            _section = rowOf[1]
                            _row = rowOf[2]
                            _of = rowOf[3]
                        }

                    }
                })
            } else {
                msg += action.chunk
            }
            if (msg) serverProgress.msg = msg
            if (_p && !_section) {
                _section = 'general'
                const previous = serverProgress?.sections?.general?._p || 0
                _p = Number(_p) + Number(previous)
            }
            serverProgress.sections = serverProgress?.sections || {}
            if (_section) {
                serverProgress.sections[_section] = {
                    _p,
                    _row,
                    _of,
                }
            }
            let totalP = 0
            for (const sectionKey of Object.keys(serverProgress.sections)) {
                const {_p,_row,_of} = serverProgress.sections[sectionKey]
                if (_of) {
                    totalP += (_row / _of) * _p
                } else {
                    totalP += _p
                }
            }
            serverProgress.percentage = Math.min(totalP,1)
            if (action?.statusCodeOverride){
                serverProgress.error = true
                serverProgress.statusCode = action?.statusCodeOverride
            }
            return state.set(SERVER_PROGRESS, fromJS(serverProgress))
        case RESET_PROGRESS:
            return state.set(SERVER_PROGRESS,fromJS({}))
        default:
            return state
    }
}
