import {getTenantUrlFromCookie, getTenantUrlFromUrl} from 'Helpers/tenants'
import {useHistory} from 'react-router-dom'
import { push } from 'connected-react-router'
import store from 'Redux/store'


export const redirect = (path) => {
  const tenantUrl = getTenantUrlFromCookie() || getTenantUrlFromUrl()
  const targetUrl = tenantUrl ? (`${tenantUrl}${path}`) : '/'
  store.dispatch(push(targetUrl))
}
const useRedirect = () => {
  const history = useHistory()
  return (path) => {
    const tenantUrl = getTenantUrlFromCookie() || getTenantUrlFromUrl()
    const targetUrl = tenantUrl ? (`${tenantUrl}${path}`) : '/'
    history.push(targetUrl)
  }
}
export default useRedirect
