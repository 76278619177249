import {useCallback, useState} from 'react'

export function israeliIdOk(id) {
  if ((id)?.length !== 9 || isNaN(id)) {
    return false;
  }
  let sum = 0, incNum;
  for (let i = 0; i < (id).length; i++) {
    incNum = Number((id)[i]) * ((i % 2) + 1);
    sum += (incNum > 9) ? incNum - 9 : incNum;
  }
  return (sum % 10 === 0);
}
export default function useIsraeliIdValidator() {
  const [israeliIdErrors, setIsraeliIdErrors] = useState('')
  const handleAndValidateIsraeliId = useCallback(({target:{value}}) => {
    setIsraeliIdErrors(!value || israeliIdOk(value) ? '' : 'מספר תעודת זהות אינו תקין')
  }, []);

  return [handleAndValidateIsraeliId, israeliIdErrors]
};
