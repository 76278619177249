import {
    AVAILABLE_SPEEDS,
    GET_AVAILABLE_SPEEDS_RESULT,
    GET_CITIES_RESULT,
    GET_STREETS_RESULT,
    IS_MAIN_LOADING,
    IS_MOCKUP_MODE,
    IS_SCROLL_WANTED,
    SET_IS_MAIN_LOADING,
    SET_IS_MOCKUP_MODE,
    SET_IS_SCROLL_WANTED
} from 'Containers/AppGeneral/consts'
import {fromJS} from "immutable";
import {CITIES,  STREETS} from 'Redux/consts'

const initialState = fromJS({
    [IS_MAIN_LOADING]: true,
    [IS_SCROLL_WANTED]: true,
    [CITIES]: [],
    [STREETS]: [],
    [IS_MOCKUP_MODE]: false,
    [AVAILABLE_SPEEDS]: [],
});

export default function AppGeneralReducer(state = initialState, action){
    switch (action.type) {
        case SET_IS_MAIN_LOADING:
            return state.set(IS_MAIN_LOADING, action.isMainLoading)
        case GET_CITIES_RESULT:
            return state.set(CITIES, fromJS(action.cities))
        case GET_STREETS_RESULT:
            return state.set(STREETS, fromJS(action.streets))
        case SET_IS_SCROLL_WANTED:
            return state.set(IS_SCROLL_WANTED, action.isScrollWanted)
        case SET_IS_MOCKUP_MODE:
            return state.set(IS_MOCKUP_MODE, action.value)
        case GET_AVAILABLE_SPEEDS_RESULT:
            return state.set(AVAILABLE_SPEEDS, fromJS(action.speeds))

        default:
            return state
    }
}
