import NewIcon from '@mui/icons-material/AddCircle'
import SubscriptIcon from '@mui/icons-material/Subscript';
import {Button, debounce, TextField} from '@mui/material'
import Mirror from 'Components/Generic/Mirror'
import Skeleton from 'Components/Skeleton'
import {loadOrdersXLSX} from 'Containers/Orders/actions'
import OrdersTable from 'Containers/Orders/OrdersTable/OrdersTable'
import useRedirect from 'Hooks/useRedirect'
import * as React from 'react'
import styles from './Orders.module.scss'

const Orders = ({ordersKeyword, setOrdersKeyword,loadOrdersXLSX}) => {
  const redirect = useRedirect()
  const _setOrdersKeyword = debounce(change => {
    setOrdersKeyword(change);
  }, 250);

  return <Skeleton className={styles.container}>
    <h2>רשימת הזמנות </h2>
    <div className={styles.topButtons}>
      <div>
        <Button variant={"contained"}
                color={"primary"}
                startIcon={<NewIcon/>} onClick={() => {
          redirect('/orders/new')
        }}>הזמנה חדשה</Button>
        <Mirror>
          <TextField
            label="חיפוש"
            type="search"
            variant="standard"
            onChange={({target: {value}}) => _setOrdersKeyword(value)}
            defaultValue={ordersKeyword}
          />
        </Mirror>
      </div>
      <Button variant={"contained"}
              color={"primary"}
              startIcon={<SubscriptIcon/>}
              onClick={() => {
        loadOrdersXLSX()
      }}>יצא לאקסל</Button>
    </div>
    <OrdersTable/>
  </Skeleton>
}
export default Orders
