
export const CREATE_TICKET_WS8 = 'CREATE_TICKET_WS8'

export const RETRIEVE_TICKET_WS7 = 'RETRIEVE_TICKET_WS7'
export const RETRIEVE_TICKET_WS7_RESULT = 'RETRIEVE_TICKET_WS7_RESULT'
export const RETRIEVE_TICKET_IS_LOADING = 'retrieveTicketIsLoading'
export const SET_RETRIEVE_TICKET_IS_LOADING = 'SET_RETRIEVE_TICKET_IS_LOADING'

export const EDIT_TICKET_FORM_VALUES = 'editTicketFormValues'
export const SET_EDIT_TICKET_FORM_VALUES = 'SET_EDIT_TICKET_FORM_VALUES'

export const CREATE_TICKET_FORM_VALUES = 'createTicketFormValues'
export const SET_CREATE_TICKET_FORM_VALUES = 'SET_CREATE_TICKET_FORM_VALUES'

export const CREATE_TICKET_IS_LOADING = 'createTicketIsLoading'
export const SET_CREATE_TICKET_IS_LOADING = 'SET_CREATE_TICKET_IS_LOADING'

export const LOAD_TICKETS = 'LOAD_TICKETS'
export const LOAD_TICKETS_RESULT = 'LOAD_TICKETS_RESULT'
export const TICKETS_TABLE = 'ticketsTable'
export const TABLE_DATA = 'tableData'
export const HAS_MORE = 'hasMore'
export const LOAD_TICKETS_IS_LOADING = 'loadTicketsIsLoading'
export const SET_LOAD_TICKETS_IS_LOADING = 'SET_LOAD_TICKETS_IS_LOADING'

export const LOAD_SINGLE_TICKET = 'LOAD_SINGLE_TICKET'
export const LOAD_SINGLE_TICKET_RESULT = 'LOAD_SINGLE_TICKET_RESULT'
export const SINGLE_TICKET = 'singleTicket'
export const TICKET_RETRIEVALS = 'ticketRetrievals'

export const SET_LOAD_SINGLE_TICKET_IS_LOADING = 'SET_LOAD_SINGLE_TICKET_IS_LOADING'
export const LOAD_SINGLE_TICKET_IS_LOADING = 'loadSingleTicketIsLoading'

export const TICKETS_KEYWORD = 'ticketsKeyword'
export const SET_TICKETS_KEYWORD = 'SET_TICKETS_KEYWORD'
