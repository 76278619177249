import apiServer, {apiMockupServer} from 'Api/Backend'
import getConfig from 'Config/config'
import {setIsScrollWanted} from 'Containers/AppGeneral/actions'
import {getLoggedInUser} from 'Containers/Auth/selectors'
import {
  loadOrdersResult, loadSingleOrderResult, locateByGisWs16Result,
  locateCustomerWs1Result, retrieveOrderWs3Result, retrieveTagWs14Result, setActivateGponIsLoading, setCancelOrderIsLoading, setCloseOrderAndRemoveLineIsLoading, setCreateOrderFormValues,
  setCreateOrderIsLoading,
  setEditOrderFormValues, setFinalizeOrderIsLoading,
  setLoadOrdersIsLoading,
  setLoadSingleOrderIsLoading, setLocateByGisFormValues, setLocateByGisIsLoading,
  setLocateCustomerInfraIsLoading, setRedirectToCreateCheckLineIsLoading, setRedirectToCreateTicketIsLoading, setRedirectToShowUserTicketsIsLoading, setRetrieveOrderIsLoading, setRetrieveTagIsLoading,
} from 'Containers/Orders/actions'
import {
  ACTIVATE_GPON_WS15, CANCEL_ORDER, CLOSE_ORDER_AND_REMOVE_LINE,
  CREATE_CHECK_LINE_FOR_ORDER,
  CREATE_OR_UPDATE_ORDER,
  CREATE_TICKET_FOR_ORDER,
  FINALIZE_ORDER_WS12,
  LOAD_ORDERS, LOAD_ORDERS_XLSX,
  LOAD_SINGLE_ORDER,
  LOCATE_BY_GIS_WS16,
  LOCATE_CUSTOMER_WS1, OPEN_USER_TICKETS_BY_ORDER,
  RETRIEVE_ORDER_WS3,
  RETRIEVE_TAG_WS14
} from 'Containers/Orders/consts'
import {getCreateOrderFormValues, getEditOrderFormValues} from 'Containers/Orders/selectors'
import {setCreateTicketFormValues} from 'Containers/Tickets/actions'
import {toastr} from 'Helpers/toastr'
import {formatBackendError, sleep} from 'Helpers/utils'
import {takeEvery, takeLeading, call, put, select} from 'redux-saga/effects'

function* locateCustomerWs1Saga({formData}) {
  yield put(setLocateCustomerInfraIsLoading(true))
  try {
    yield put(locateCustomerWs1Result(null))
    const {
      israeliId: idNumber,
      cityRow = {},
      streetRow = {},
      fourDigits: last4Numbers,
      lineCode, mobileNumber, houseNum, letter, firstName, lastName
    } = formData || {}

    const {
      cityId: locationCode = null,
      name: locationName = null,
    } = cityRow || {}

    const {
      streetId: streetCode = null,
      name: streetName = null,
    } = streetRow || {}

    const payload = {
      idNumber, lineCode, mobileNumber, locationCode: locationCode?.toString(),
      locationName, streetCode: streetCode.toString(), streetName, last4Numbers,
      houseNum, letter, firstName, lastName
    }
    const result = yield call(apiServer.post, 'Locating/Subscriber', payload)
    yield put(locateCustomerWs1Result({
      messageDesc: result?.errorDescription || result?.messageDesc || (result?.messageCode === 0 && 'הצלחה'),
      gponInfo: {gponExists: result?.gpon},
      packageRateMessage: result?.packageRateMassage,
      ...result
    }))
    yield put(setIsScrollWanted(true))
    const {
      // lineCode: _lineCode,
      gponInfo: {
        gponBuildingType,
        gponExists,
      } = {},
    } = result

    yield put(setCreateOrderFormValues({
      ...formData,
      ...result,
      gponBuildingType,
      gponApartment: Number(gponExists) === 1,
      // lineCode: _lineCode,
    }))
  } catch (e) {
    console.error(e)
  }
  yield put(setLocateCustomerInfraIsLoading(false))
}

function* createOrUpdateOrderSaga({redirect, orderId}) {
  yield put(setCreateOrderIsLoading(true))
  try {

    const loggedInUser = yield select(getLoggedInUser)
    console.log('gizim', 4195363, {loggedInUser,orderId})

    if (orderId) {

      const formData = yield select(getEditOrderFormValues)
      const {
          uplink,
          downlink,
      } = formData;
      const payload = {
        orderId: Number(orderId),
        uplink: uplink.toString(),
        downlink: downlink.toString(),
      }
      const result = yield call(apiServer.post, 'Order/ChangePackage', payload)
      if (result.isSuccess) {
        const {messageCode, messageDesc} = result
        toastr.success(`הזמנה מס ${orderId} עודנה בהצלחה, סטטוס ${messageDesc}`)
        yield call(sleep, 2000)
        redirect(`/orders/${orderId}`)
      }
    } else {
      // new order
      const formData = yield select(getCreateOrderFormValues)
      const {
        firstName,
        lastName,
        lineCode,
        israeliId,
        houseNum,
        houseLetter,
        floor,
        providerComments,
        contactPhone,
        planSoftware,
        apartment,
        gponBuildingType,
        entrance,
        cityRow: {
          cityId: locationCode,
          name: locationName,
        } = {},
        streetRow: {
          streetId: streetCode,
          name: streetName,
        } = {},
        serialNumber,
        gponApartment,
        installToHome,
        gponTechnician,
        reservedNetwork,
        downlink,
        uplink,
        bezeqPackageCode,
        mobility,
      } = formData;
      const payload = {
        lineCode: lineCode.toString(),
        idNumber: israeliId.toString(),
        mobileNumber: contactPhone.toString(),
        bezeqHomeTechnician: installToHome,
        requestedDate: new Date(),
        comment4Bezeq: providerComments,
        locationCode: locationCode.toString(),
        locationName,
        streetCode: streetCode.toString(),
        streetName,
        houseNum: houseNum.toString(),
        apartmentNumber: apartment.toString(),
        appartmentNumber: apartment.toString(), // backend has internal type in one place so it needs both versions
        entrance,
        floor: floor.toString(),
        letter: houseLetter,
        infrastructure: "BezeqFiber", // ???????"BezeqBronze",  "BezeqFiber"
        firstName,
        lastName,
        // "last4CCNumber": "string",
        email: getConfig().providerEmail,
        uplink: uplink.toString(),
        downlink: downlink.toString(),
        bezeqPackageCode: bezeqPackageCode.toString(),
        userName: loggedInUser?.email,
        mobility
      }
      const result = yield call(apiServer.post, 'Order/Create', payload)
      if (result.isSuccess) {
        const {orderID, orderStatus} = result
        toastr.success(`הזמנה מס ${orderID} נוצרה בהצלחה, סטטוס ${orderStatus}`)
        yield call(sleep, 2000)
        redirect(`/orders/${orderID}`)
      }
    }
  } catch (e) {
    console.error(e)
  }
  yield put(setCreateOrderIsLoading(false))
}

function* loadOrdersSaga({limit, offset, keyword}) {
  yield put(setLoadOrdersIsLoading(true))
  try {
    const result = yield call(apiServer.get, 'Order', {params: {limit, offset, keyword}})
    const orders = {}
    for (const [id, order] of Object.entries(result?.orders || {})) {
      orders[order?.orderID] = {
        fullName: order?.firstName + ' ' + order?.lastName,
        israeliId: order?.idNumber,
        lineCode: order?.lineCode,
        userName: order?.userName,
      }
    }
    const hasMore = !!(result?.hasMore)
    yield put(loadOrdersResult({orders, hasMore}))


  } catch (e) {
    console.error(e)
  }
  yield put(setLoadOrdersIsLoading(false))
}

function* loadOrdersXLSXSaga() {
  yield put(setLoadOrdersIsLoading(true))
  try {
    const response = yield call(apiServer.getBlob, 'admin/DownloadOrdersAsXLSX', {})

    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', 'Report.xlsx')
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

  } catch (e) {
    console.error(e)
  }
  yield put(setLoadOrdersIsLoading(false))
}


function* loadSingleOrderSaga({orderId}) {
  yield put(setLoadSingleOrderIsLoading(true))
  try {
    const augmentedResult = yield call(apiServer.get, `Order/ID/${orderId}`)
    const result = augmentedResult?.orderRetrievals?.[0] || augmentedResult?.retrievals?.[0] || augmentedResult?.orders?.[0]
    const singleResult = {
      ServiceOrderTypeCode: result?.bezeqOrderStatus,
      email: result?.email,
      customerID: result?.customerID,
      apartment: result?.apartment,
      assignedLineCode: "",
      bezeqOrderId: result?.bezeqOrderID,
      cityRow: {cityId: result?.orderLocationCode, name: result?.orderLocationName},
      contactPhone: result?.mobilePhone,
      currProviderId: "0",
      entrance: "0",
      firstName: result?.firstName,
      floor: result?.floor,
      gponApartment: true,
      gponBuildingType: "",
      gponTechnician: false,
      houseLetter: result?.orderLetter,
      houseNum: result?.orderHouseNumber,
      installToHome: result?.technicianType === 2,
      israeliId: result?.idNumber,
      lastName: result?.lastName,
      lineCode: result?.lineCode,
      messageCode: result?.messageCode,
      messageDesc: result?.messageDesc,
      orderId,
      orderOpenDate: "",
      // orderRetrievals:[{messageSendTimeReturn: "202305291542", phoneNum: "036221829", assignedLineCode: "038963356",…},…]
      orderStatusCode: result?.errorCode,
      orderStatusDesc: result?.orderStatus + '/' + result?.bezeqOrderStatus,
      providerComments: "",
      requestedPackage: "",
      reservedNetwork: false,
      streetRow: {cityId: result?.orderLocationCode, streetId: result?.orderStreetCode, name: result?.orderStreetName},
      mobility: result?.mobility,
      serialNumber: result?.serialNumber,
      uplink: result?.uplink,
      downlink: result?.downlink,
      bezeqPackageCode: result?.bezeqPackageCode,
      // techMessageCode:0,
      // techMessageDesc:"",
    }
    console.log('gizim', 855912, {result, singleResult});

    yield put(loadSingleOrderResult(singleResult))
    yield put(setEditOrderFormValues(singleResult))
  } catch (e) {
    console.error(e)
  }
  yield put(setLoadSingleOrderIsLoading(false))
}

function* retrieveOrderWs3Saga({orderId}) {
  yield put(setRetrieveOrderIsLoading(true))
  try {
    const result = yield call(apiMockupServer.put, 'RetrieveOrder', {orderId: Number(orderId)})
    yield call(sleep, 2000)
    yield put(retrieveOrderWs3Result(result))
  } catch (e) {
    console.error(e)
    toastr.error('תקלה באחזור סטטוס הזמנה');
  }
  yield put(setRetrieveOrderIsLoading(false))
}

function* finalizeOrderWs12Saga({orderId}) {
  yield put(setFinalizeOrderIsLoading(true))
  try {
    const result = yield call(apiServer.post, 'Order/Confirm', {orderId: Number(orderId)})
    // yield put(retrieveOrderWs3Result(result))
    if (result) {
      toastr.success(`תהליך מכירה וניתוק ספק ישן להזמנה [${orderId}] הסתיים בהצלחה`)
    }
  } catch (e) {
    console.error(e)
    toastr.error('תקלה בסיום תהליך מכירה');
  }
  yield put(setFinalizeOrderIsLoading(false))
}

function* retrieveTagWs14Saga({orderId}) {
  yield put(setRetrieveTagIsLoading(true))
  try {
    const result = yield call(apiServer.post, 'Fiber/RetrieveFdhData', {orderId: Number(orderId)})

    if (result) {
      yield put(retrieveTagWs14Result(result))
    }
  } catch (e) {
    console.error(e)
    toastr.error('תקלה באחזור TAG');
  }
  yield put(setRetrieveTagIsLoading(false))
}


function* activateGponWs15Saga({
                                 orderId,
                                 serialNumber,
                                 fdhCustomerPortNum,
                                 fdhId,
                                 fdhTag,
                                 fdtFloor,
                                 fdtLocationCode,
                                 fdtStreetCode,
                                 fdtHouseNum,
                                 fdtTag,
                                 fdtLetter,
                                 apartmentNumber,
                                 isClosedOrder,
                               }) {
  yield put(setActivateGponIsLoading(true))
  try {

    const result = yield call(apiServer.post,
      (isClosedOrder ? 'Device/ChangeDevice' : 'Device/Create'),
      {
        orderId: Number(orderId),
        serialNumber,
        newSerialNumber: serialNumber,
        fdhCustomerPortNum,
        fdhId,
        fdhTag,
        fdtTag,
        fdtFloor,
        fdtLocationCode,
        fdtStreetCode,
        fdtHouseNum,
        fdtHouseNumber: fdtHouseNum,
        fdtLetter,
        apartmentNumber,
      })
    if (result) {
      toastr.success(`איקטוב GPON להזמנה [${orderId}] הסתיים בהצלחה`)
    }
  } catch (e) {
    console.error(e)
    toastr.error('תקלה באיקטוב GPON');
  }
  yield put(setActivateGponIsLoading(false))
}

function* locateByGisWs16Saga({formData}) {
  yield put(setLocateByGisIsLoading(true))
  try {
    yield put(locateByGisWs16Result(null))
    const requestData = {...formData, lat: formData?.y, lon: formData?.x}
    console.log('gizim locateByGis ', {requestData});

    const result = yield call(apiServer.post, 'Locating/Coordinates', requestData)
    if (result?.messageCode > 0) {
      toastr.error(`שגיאה בחיפוש גאוגרפי ${result?.messageDesc}`)
      yield put(setLocateByGisFormValues({shouldCloseModal: true}))
    } else {
      yield put(locateByGisWs16Result(result))
      const formValues = {
        cityRow: {cityId: result?.locationCode, name: result?.locationName},
        streetRow: {cityId: result?.locationCode, streetId: result?.streetCode, name: result?.streetName},
        houseNum: result?.houseNumber,
        houseLetter: result?.letter,
        x: formData?.x,
        y: formData?.y,
      }
      yield put(setLocateByGisFormValues(formValues))
      yield put(setCreateOrderFormValues(formValues))
    }
  } catch (e) {
    toastr.error(`שגיאה באיתור גאוגרפי ${e}`)
    console.error(e)
  }
  yield put(setLocateByGisIsLoading(false))
}

function* createTicketForOrderSaga({orderId, redirect, orderFormValues}) {
  try {
    yield put(setCreateTicketFormValues(orderFormValues))
    yield put(setRedirectToCreateTicketIsLoading(true))
    yield call(sleep, 1000)
    yield put(setRedirectToCreateTicketIsLoading(false))
    redirect(`/tickets/new/${orderId}`)
  } catch (e) {
    console.error(e)
  }
}

function* createChecklineForOrderSaga({orderId, redirect}) {
  try {
    yield put(setRedirectToCreateCheckLineIsLoading(true))
    yield call(sleep, 1000)
    yield put(setRedirectToCreateCheckLineIsLoading(false))
    redirect(`/checklines/new/${orderId}`)
  } catch (e) {
    console.error(e)
  }
}

function* openUserTicketsByOrderSaga({orderId, redirect}) {
  try {
    yield put(setRedirectToShowUserTicketsIsLoading(true))
    yield call(sleep, 1000)
    yield put(setRedirectToShowUserTicketsIsLoading(false))
    redirect(`/user-tickets-by-order/${orderId}`)
  } catch (e) {
    console.error(e)
  }
}

function* cancelOrderSaga({orderId}) {
  yield put(setCancelOrderIsLoading(true))
  try {
    const result = yield call(apiServer.delete, `Order/Cancel/${orderId}`)
    if (result.isSuccess) {
      toastr.success(`ביטול הזמנה [${orderId}] הסתיים בהצלחה`)
    } else {
      toastr.error(formatBackendError(result))
    }
  } catch (e) {
    console.error(e)
    toastr.error('תקלה בביטול הזמנה');
  }
  yield put(setCancelOrderIsLoading(false))
}

function* closeOrderAndRemoveLineSaga({orderId}) {

  yield put(setCloseOrderAndRemoveLineIsLoading(true))
  try {
    const result = yield call(apiServer.delete, `Order/Close/${orderId}`)
    if (result?.isSuccess) {
      toastr.success(`סגירת הזמנה והסרת הקו [${orderId}] הסתיימה בהצלחה`)
    }
  } catch (e) {
    console.error(e)
    toastr.error('תקלה בסגירת הזמנה והסרת הקו')
  }
  yield put(setCloseOrderAndRemoveLineIsLoading(false))
}

export default function* OrdersSaga() {
  yield takeEvery(LOCATE_CUSTOMER_WS1, locateCustomerWs1Saga)
  yield takeEvery(CREATE_OR_UPDATE_ORDER, createOrUpdateOrderSaga)
  yield takeLeading(LOAD_ORDERS, loadOrdersSaga)
  yield takeEvery(LOAD_SINGLE_ORDER, loadSingleOrderSaga)
  yield takeEvery(RETRIEVE_ORDER_WS3, retrieveOrderWs3Saga)
  yield takeEvery(FINALIZE_ORDER_WS12, finalizeOrderWs12Saga)
  yield takeEvery(RETRIEVE_TAG_WS14, retrieveTagWs14Saga)
  yield takeEvery(ACTIVATE_GPON_WS15, activateGponWs15Saga)
  yield takeEvery(LOCATE_BY_GIS_WS16, locateByGisWs16Saga)
  yield takeEvery(CREATE_TICKET_FOR_ORDER, createTicketForOrderSaga)
  yield takeEvery(CREATE_CHECK_LINE_FOR_ORDER, createChecklineForOrderSaga)
  yield takeEvery(OPEN_USER_TICKETS_BY_ORDER, openUserTicketsByOrderSaga)
  yield takeEvery(CANCEL_ORDER, cancelOrderSaga)
  yield takeEvery(CLOSE_ORDER_AND_REMOVE_LINE, closeOrderAndRemoveLineSaga)
  yield takeEvery(LOAD_ORDERS_XLSX, loadOrdersXLSXSaga)
}
