import {getTenant} from 'Helpers/tenants'
import {connect} from 'react-redux';
import {Redirect, Route} from "react-router-dom";
import React from "react";
import {getIsConnected} from 'Containers/Auth/selectors'


const Empty = () => <></>

function PublicOrPrivateRoute({isConnected, computedMatch, location, children, Component = Empty, ...rest}) {
  let correctUrlByConnection
  if (isConnected) {
    const tenant = getTenant()
    if (tenant && computedMatch?.params?.tenantId !== tenant.url.substr(1) && location.pathname !== '/swagger' && location.pathname !== tenant.url) {
      correctUrlByConnection = tenant.url
    }
  }
  return (
    <Route
      computedMatch={computedMatch}
      {...rest}
      render={({location, match}) => {
        return (correctUrlByConnection ? <Redirect
              to={{
                pathname: correctUrlByConnection,
                state: {from: location}
              }}
            />
            : children || <Component location={location} match={match}/>
        )
      }}
    />
  );
}

const mapStateToProps = (state,) => {
  const isConnected = getIsConnected(state)

  return {
    isConnected,
  }
};

export default connect(mapStateToProps, null)(PublicOrPrivateRoute);
