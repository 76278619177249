import Typography from '@mui/joy/Typography'
import {Accordion, AccordionDetails, AccordionSummary, Paper} from '@mui/material'
import {formatBezeqDate} from 'Components/Tickets/ticketsUtils'
import {TECHNICIAN_TYPE} from 'Containers/AppGeneral/consts'
import {ORDER_RETRIEVALS} from 'Containers/Orders/consts'
import * as React from 'react'
import {useState} from 'react'
import styles from './orderRetrival.module.scss'

export default function OrderRetrievals({singleOrder} = {}) {
  const [expanded, setExpanded] = useState('')

  const orderRetrievals = singleOrder?.[ORDER_RETRIEVALS] || []

  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    }

  return <div>
    <div>
      {orderRetrievals.map((singleRetrieval, index) => {
        return <Accordion key={`retrieval${index}-key`} expanded={expanded === `retrieval${index}`} onChange={handleChange(`retrieval${index}`)}>
          <AccordionSummary aria-controls={`retrieval${index}-content`} id={`retrieval${index}-header`}>
            <Typography>עדכון מתאריך {formatBezeqDate(singleRetrieval?.messageSendTimeReturn)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.retrievalContainer}>
              <div>
                <div>קוד קו</div>
                <div>{singleRetrieval?.phoneNum}</div>
              </div>
              <div>
                <div>קוד קו מוקצה</div>
                <div>{singleRetrieval?.assignedLineCode}</div>
              </div>
              <div>
                <div>מועד סיום טיפול משוער</div>
                <div>{singleRetrieval?.SlaTo}</div>
              </div>
              <div>
                <div>חבילה בפועל</div>
                <div>{singleRetrieval?.requestedPackage}</div>
              </div>
              <div>
                <div>נדרש טכנאי</div>
                <div>{TECHNICIAN_TYPE[singleRetrieval?.technicianType]}</div>
              </div>
              <div>
                <div>קוד סוג הוראת עבודה</div>
                <div>{singleRetrieval?.ServiceOrderTypeCode}</div>
              </div>
              <div>
                <div>מועד מתואם – בית מנוי - משעה</div>
                <div>{formatBezeqDate(singleRetrieval?.assignedInstallationTimeFrom)}</div>
              </div>
              <div>
                <div>מועד מתואם – בית מנוי – עד שעה</div>
                <div>{formatBezeqDate(singleRetrieval?.assignedInstallationTimeTo)}</div>
              </div>
              <div>
                <div>תאריך פתיחת הזמנה</div>
                <div>{formatBezeqDate(singleRetrieval?.orderOpenDate)}</div>
              </div>
              <div>
                <div>תאריך סגירת הזמנה</div>
                <div>{formatBezeqDate(singleRetrieval?.orderClosingDate)}</div>
              </div>
              <div>
                <div>נתב בבעלות בזק</div>
                <div>{singleRetrieval?.bezeqOwnModem ? 'כן' : 'לא'}</div>
              </div>
              <div>
                <div>סוג נתב</div>
                <div>{singleRetrieval?.modemType}</div>
              </div>
              <div>
                <div>Box</div>
                <div>{singleRetrieval?.box}</div>
              </div>
              <div>
                <div>Tag</div>
                <div>{singleRetrieval?.tag}</div>
              </div>
              <div>
                <div>ActualBitRate</div>
                <div>{singleRetrieval?.actualBitRate}</div>
              </div>
              <div>
                <div>MaxAttainable</div>
                <div>{singleRetrieval?.maxAttainable}</div>
              </div>
              <div>
                <div>LineAttenuation</div>
                <div>{singleRetrieval?.lineAttenuation}</div>
              </div>
              <div>
                <div>Noise</div>
                <div>{singleRetrieval?.noise}</div>
              </div>
              <div>
                <div>Distance</div>
                <div>{singleRetrieval?.distance}</div>
              </div>
              <div>
                <div>קוד ספק נוכחי</div>
                <div>{singleRetrieval?.currProviderId}</div>
              </div>
              <div>
                <div>קוד סטטוס הזמנה</div>
                <div>{singleRetrieval?.orderStatusCode}</div>
              </div>
              <div>
                <div>תאור סטטוס הזמנה</div>
                <div>{singleRetrieval?.orderStatusDesc}</div>
              </div>
              <div>
                <div>תאור הודעה</div>
                <div>{singleRetrieval?.messageDesc}</div>
              </div>
            </div>
            {singleRetrieval?.fdhId && <Paper elevation={3} className={styles.Ws14Container}>
              <h2>מידע על תג מוקצה במפצל בלובי</h2>
              <div>
                <div>מזהה FDH</div>
                <div>{singleRetrieval?.fdhId}</div>
              </div>
              <div>
                <div>תג FDH</div>
                <div>{singleRetrieval?.fdhTag}</div>
              </div>
              <div>
                <div>סמל יישוב</div>
                <div>{singleRetrieval?.locationCode}</div>
              </div>
              <div>
                <div>סמל רחוב</div>
                <div>{singleRetrieval?.streetCode}</div>
              </div>
              <div>
                <div>מספר בית</div>
                <div>{singleRetrieval?.houseNum}</div>
              </div>
              <div>
                <div>אות</div>
                <div>{singleRetrieval?.letter}</div>
              </div>
              <div>
                <div>סמל יישוב FDT</div>
                <div>{singleRetrieval?.fdtLocationCode}</div>
              </div>
              <div>
                <div>סמל רחוב FDT</div>
                <div>{singleRetrieval?.fdtStreetCode}</div>
              </div>
              <div>
                <div>מספר בית FDT</div>
                <div>{singleRetrieval?.fdtHouseNum}</div>
              </div>
              <div>
                <div>אות FDT</div>
                <div>{singleRetrieval?.fdtLetter}</div>
              </div>
              <div>
                <div>הערות FDT</div>
                <div>{singleRetrieval?.fdtComments}</div>
              </div>
              <div>
                <div>קוד הודעה FDT</div>
                <div>{singleRetrieval?.fdtMessageCode}</div>
              </div>
              <div>
                <div>תאור הודעה FDT</div>
                <div>{singleRetrieval?.fdtMessageDesc}</div>
              </div>
            </Paper>}
          </AccordionDetails>
        </Accordion>
      })}
    </div>
  </div>
}
