import CheckLines from 'Components/CheckLines/CheckLines'
import {setCheckLinesKeyword} from 'Containers/CheckLines/actions'
import {getCheckLinesKeyword} from 'Containers/CheckLines/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    setCheckLinesKeyword: keyword => dispatch(setCheckLinesKeyword(keyword))
  }
};
const mapStateToProps = (state,) => {
  return {
    checkLinesKeyword: getCheckLinesKeyword(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckLines)
