import Skeleton from 'Components/Skeleton'
import React from "react";
import LoginLogoutBox from "../../../Containers/Auth/LoginLogoutBox";
import styles from './TenantPage.module.scss'

const TenantPage = () => {
  return <Skeleton className={styles.content}>
      <LoginLogoutBox/>
    </Skeleton>
}

export default TenantPage;
