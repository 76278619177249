import AdminConsoleSaga from 'Containers/AdminConsole/sagas'
import AppGeneralSaga from 'Containers/AppGeneral/sagas'
import OrdersSaga from 'Containers/Orders/sagas'
import CheckLinesSaga from 'Containers/CheckLines/sagas'
import TicketsSaga from 'Containers/Tickets/sagas'
import ToastrSaga from 'Containers/Toastr/sagas';
import AuthSaga from 'Containers/Auth/sagas';

const sagas =  [
    ToastrSaga,
    AuthSaga,
    AppGeneralSaga,
    OrdersSaga,
    CheckLinesSaga,
    TicketsSaga,
    AdminConsoleSaga,
];
export default sagas
