import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import styles from 'Components/Orders/OrdersTable/index.module.scss'
import {TABLE_DATA} from 'Containers/Orders/consts'
import React from 'react'

const OrdersContainerHolder = React.forwardRef((props, ref) => {
  const {handleClick} = props
  const tableData = props?.[TABLE_DATA]

  return <TableContainer className={styles.tableContainerHolder}  ref={ref}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>מזהה</TableCell>
          <TableCell className={styles.notOnMobile}>ת.ז.</TableCell>
          <TableCell>קוד קו</TableCell>
          <TableCell>שם</TableCell>
          <TableCell>בעלים</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(tableData).sort((a, b) => a - b).reverse().map(id => (
          <TableRow key={id}
                    hover
                    onClick={(event) => handleClick(event, id)}
          >
            <TableCell>{id}</TableCell>
            <TableCell className={styles.notOnMobile}>{tableData[id]?.israeliId}</TableCell>
            <TableCell>{tableData[id]?.lineCode}</TableCell>
            <TableCell>{tableData[id]?.fullName}</TableCell>
            <TableCell>{tableData[id]?.userName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
})

export default OrdersContainerHolder
