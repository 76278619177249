import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import styles from 'Components/Tickets/TicketsTable/index.module.scss'
import {SERVICE_ORDER_TYPE_CODE, TASK_STATUS_CODE} from 'Containers/AppGeneral/consts'
import {TABLE_DATA} from 'Containers/Tickets/consts'
import React from 'react'

const TicketsContainerHolder = React.forwardRef((props, ref) => {
  const {handleClick} = props
  const tableData = props?.[TABLE_DATA]
  return <TableContainer className={styles.tableContainerHolder}  ref={ref}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={styles.notOnMobile}>מזהה תקלה</TableCell>
          <TableCell className={styles.notOnMobile}>providerComments</TableCell>
          <TableCell>serviceOrderTypeDesc</TableCell>
          <TableCell className={styles.notOnMobile}>status</TableCell>
          <TableCell>faultType</TableCell>
          <TableCell>serviceOrderTypeCode</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(tableData).map(id => (
          <TableRow key={id}
                    hover
                    onClick={(event) => handleClick(event, id)}
          >
            <TableCell className={styles.notOnMobile}>{tableData[id]?.faultId}</TableCell>
            <TableCell className={styles.notOnMobile}>{tableData[id]?.providerCommants || tableData[id]?.providerComments}</TableCell>
            <TableCell>{tableData[id]?.serviceOrderTypeDesc}</TableCell>
            <TableCell className={styles.notOnMobile}>{tableData[id]?.status}</TableCell>
            <TableCell>{tableData[id]?.faultType}</TableCell>
            <TableCell>{SERVICE_ORDER_TYPE_CODE?.[Number(tableData[id].serviceOrderTypeCode)]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
})

export default TicketsContainerHolder
