import {FacebookCircularProgress} from 'Components/Generic/Spinner'
import React, {useState, useLayoutEffect, useCallback, useEffect} from 'react'
import styles from './styles.module.scss'

const InfiniteScrollTable = ({TableContainer, loadItems, hasMore, isLoading, tableProps}) => {
  const tableEl = React.createRef()
  const [distanceBottom, setDistanceBottom] = useState(0)

  const scrollListener = useCallback(() => {
    const loadMore = () => {
      loadItems()
    }
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2))
    }
    if (!isLoading && tableEl.current.scrollTop > bottom - distanceBottom && hasMore) {
      loadMore()
    }
  }, [tableEl, distanceBottom, hasMore, isLoading, loadItems])

  useLayoutEffect(() => {
    const tableRef = tableEl?.current
    if (!tableRef) return
    tableRef.addEventListener('scroll', scrollListener)
    return () => {
      tableRef.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener, tableEl])

  return (
    <div className={styles.container}>
      {isLoading &&
        <div className={styles.spinner}><FacebookCircularProgress/></div>
      }
      <TableContainer ref={tableEl} {...tableProps}/>
    </div>
  )
}
export default InfiniteScrollTable
