import ClearFormIcon from '@mui/icons-material/ClearSharp'
import {Button} from '@mui/material'
import Skeleton from 'Components/Skeleton'
import EditTicketPanel from 'Containers/Tickets/EditTicketPanel/EditTicketPanel'
import {useMemo, useState} from 'react'
import styles from './CreateTicket.module.scss'

const CreateTicket = ({orderId,createTicketFormValues, setCreateTicketFormValues}) => {

  const [editTicketComponentKey, setEditTicketComponentKey] = useState(0)

  // clear/reload EditTicketPanel on a new successful infra search
  useMemo(() => {
    setEditTicketComponentKey(prev => prev + 1)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createTicketFormValues, setEditTicketComponentKey])

  const clearForm = () => {
    setCreateTicketFormValues({orderId})
    setEditTicketComponentKey(prev => prev + 1)
  }

  return <Skeleton className={styles.ticketPageContainer}>
      <div className={styles.ticketHeaderContainer}>
        <h3>תקלה חדשה&nbsp;
          {orderId && <span>(להזמנה מס׳ {orderId})</span>}
        </h3>
        <div className={styles.topButtonsPanel}>
          <Button startIcon={<ClearFormIcon/>} color="primary" onClick={clearForm}>נקה טופס</Button>
        </div>
      </div>
    <EditTicketPanel key={`editTicket${editTicketComponentKey}`} formValues={createTicketFormValues} setFormValues={setCreateTicketFormValues} orderId={orderId}/>
  </Skeleton>
}

export default CreateTicket
