import {
  LOGOUT,
  SET_IS_CONNECTED,
  SET_LOGGED_IN_USER, AUTHENTICATE, DISCONNECT, WATCH_FOR_FIREBASE_AUTH, SET_AUTHENTICATE_IS_LOADING, RESTORE_AIRGAP_SESSION,
} from './consts';

export function setIsConnected(isConnected) {
  return {
    type: SET_IS_CONNECTED,
    isConnected
  }
}

export function setLoggedInUser(loggedInUser) {
  return {
    type: SET_LOGGED_IN_USER,
    loggedInUser
  }
}

export function authenticate(email, password) {
  return {
    type: AUTHENTICATE,
    email,
    password,
  }
}

export function disconnect() {
  return {
    type: DISCONNECT,
  }
}

export function watchForFirebaseAuth() {
  return {
    type: WATCH_FOR_FIREBASE_AUTH,
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}

export function setAuthenticateIsLoading(isLoading){
    return {
        type: SET_AUTHENTICATE_IS_LOADING,
        isLoading
    }
}
export function restoreAirgapSession(){
    return {
        type: RESTORE_AIRGAP_SESSION
    }
}
