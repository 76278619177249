import AuthBox from 'Components/Auth/TenantPage/AuthBox/AuthBox'
import getConfig from 'Config/config'
import {authenticate, disconnect, setIsConnected, setLoggedInUser} from '../actions';
import {connect} from 'react-redux';
import {getAuthenticateIsLoading, getIsConnected, getLoggedInUser} from '../selectors'

const mapDispatchToProps = (dispatch) => {
  return {
    setIsConnected: (isConnected) => dispatch(setIsConnected(isConnected)),
    setLoggedInUser: (loggedInUser) => dispatch(setLoggedInUser(loggedInUser)),
    authenticate: (username,password) => dispatch(authenticate(username,password)),
    disconnect: () => dispatch(disconnect()),
  }
}

const mapStateToProps =(state, ) =>  {
  const isConnected =  getIsConnected(state)
  const loggedInUser = getLoggedInUser(state)
  const authenticateIsLoading = getAuthenticateIsLoading(state)
  const authorizationMethod = getConfig().authorizationMethod
  return {
    isConnected,
    loggedInUser,
    authorizationMethod,
    authenticateIsLoading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthBox)
