export function isEndpointOK(endpoint, timeout = 3000) {
  // Create a promise that rejects after a timeout
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Request timed out')), timeout)
  );

  // Fetch the endpoint and check the response
  const fetchPromise = fetch(endpoint)
    .then(response => response.text())
    .then(text => {
      return text.includes("unauthorized to geobez system");
    });

  // Use Promise.race to return the result of whichever promise settles first
  return Promise.race([fetchPromise, timeoutPromise])
    .catch(() => {
      return false;  // Return false on timeout or fetch errors
    });
}

