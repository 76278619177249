import {GET_CITIES_RESULT, GET_STREETS_RESULT} from 'Containers/AppGeneral/consts'
import {applyMiddleware, createStore} from "redux";
import { routerMiddleware } from 'connected-react-router/immutable'
import {APP_GENERAL, CITIES, STREETS} from 'Redux/consts'
import createReducer from './reducers';
import middlewares, {loadMiddlewares} from "./middlewares";
import {composeWithDevTools} from "redux-devtools-extension";
import {createBrowserHistory} from "history";
import * as actionCreators from 'Containers/Orders/actions';

export const history = createBrowserHistory();
const reducers = createReducer(history);

const actionSanitizer = (action) => {
  switch (action.type) {
    case GET_CITIES_RESULT:
      return {...action, cities: '<<רשימת ערים>>'}
    case GET_STREETS_RESULT:
      return {...action, streets: '<<רשימת רחובות>>'}
    default:
  }
  return action
};
const stateSanitizer = (state) => {
  return state.updateIn([APP_GENERAL,CITIES],cities => `<<${cities?.count()} ערים>>`)
    .updateIn([APP_GENERAL,STREETS],streets => `<<${streets?.count()} רחובות>>`)
}
const composeEnhancers = composeWithDevTools({
  actionSanitizer,
  stateSanitizer,
  actionCreators,
});
const store = createStore(reducers, composeEnhancers(applyMiddleware(
    ...middlewares,
    routerMiddleware(history), // for dispatching history actions
)));


loadMiddlewares(store.dispatch);
export default store;
