import {
  CREATE_CHECK_LINE_IS_LOADING,
  CREATE_CHECK_LINE_OUTCOME_WS5, CREATE_CHECK_LINE_OUTCOME_WS5_RESULT,
  CREATE_CHECK_LINE_REQUEST_WS4,
  CREATE_CHECK_LINE_REQUEST_WS4_RESULT, LOAD_CHECK_LINES, LOAD_CHECK_LINES_RESULT, SET_CHECK_LINES_KEYWORD, SET_CREATE_CHECK_LINE_IS_LOADING, SET_CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID,
  SET_CREATE_CHECK_LINE_REQUEST_IS_LOADING, SET_LOAD_CHECK_LINES_IS_LOADING,
} from 'Containers/CheckLines/consts'

export function setCreateCheckLineRequestWs4IsLoading(isLoading){
    return {
        type: SET_CREATE_CHECK_LINE_REQUEST_IS_LOADING,
        isLoading
    }
}
export function createCheckLineRequestWs4(request){
    return {
        type: CREATE_CHECK_LINE_REQUEST_WS4,
        request
    }
}
export function createCheckLineRequestWs4Result(result){
    return {
      type: CREATE_CHECK_LINE_REQUEST_WS4_RESULT,
      result
    }
}

export function loadCheckLines(offset, limit, keyword) {
  return {
    type: LOAD_CHECK_LINES,
    offset,
    limit,
    keyword,
  }
}
export function loadCheckLinesResult(result){
  return {
    type: LOAD_CHECK_LINES_RESULT,
    result
  }
}
export function setLoadCheckLinesIsLoading(isLoading){
  return {
    type: SET_LOAD_CHECK_LINES_IS_LOADING,
    isLoading
  }
}


export function setCreateCheckLineOutcomeWs5IsLoadingAndId(isLoadingAndId){
  return {
    type: SET_CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID,
    isLoadingAndId
  }
}
export function createCheckLineOutcomeWs5(id){
  return {
    type: CREATE_CHECK_LINE_OUTCOME_WS5,
    id
  }
}
export function createCheckLineOutcomeWs5Result(result){
  return {
    type: CREATE_CHECK_LINE_OUTCOME_WS5_RESULT,
    result
  }
}
export function setCheckLinesKeyword(keyword){
    return {
        type: SET_CHECK_LINES_KEYWORD,
        keyword
    }
}
