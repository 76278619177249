import CreateTicket from 'Components/Tickets/CreateTicket/CreateTicket'
import {setCreateTicketFormValues} from 'Containers/Tickets/actions'
import {getCreateTicketFormValues} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    setCreateTicketFormValues: formValues => dispatch(setCreateTicketFormValues(formValues)),
  }
};
const mapStateToProps = (state, ownProps) => {
  const {match:{params:{orderId}}} = ownProps
  return {
    createTicketFormValues: getCreateTicketFormValues(state),
    orderId,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket)
