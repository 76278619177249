import {FacebookCircularProgress} from 'Components/Generic/Spinner'
import * as React from 'react';
import styles from './TenantUsersTable.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

export default function TenantUsersTable({tenantUsers:{users = {}} = {}, setUserBeingEdited,  setIsUserEditorOpen, loadTenantUsersIsLoading, loggedInUser}) {
  const editUser = (email) => {
    setUserBeingEdited([email, users[email]])
    setIsUserEditorOpen(true)
  }

  return (
    <TableContainer className={styles.tenantUsersTable} component={Paper}>
      {loadTenantUsersIsLoading &&
        <div className={styles.spinner}><FacebookCircularProgress/></div>
      }
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Display Name</TableCell>
            <TableCell align="left">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(users).map((email) => (
            <TableRow key={email} onClick={() => editUser(email)}>
              <TableCell align="left" component="th" scope="row">{email}</TableCell>
              <TableCell align="left">{users[email].displayName}</TableCell>
              <TableCell align="left">{loggedInUser.geobez.toLowerCase()==='admin' ?
                users[email].claims.geobez.replace('god','Admin')
                :
                users[email].claims.geobez}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
