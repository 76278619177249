import {useCallback, useState} from 'react'

export default function useNumericDigitsValidator(params = {}) {
  const minLength = params?.minLength || 0
  const required = params?.required
  const [numericDigitsErrors, setNumericDigitsErrors] = useState('');
  const handleAndValidateNumericDigits = useCallback(({target:{value}}) => {
    const digitErrors = (value && !/^\d+\.?\d*$/.test(value)) ?  'יש להקליד רק מספרים' : ''
    const lengthErrors = (value && minLength>0 && value?.length < minLength) ? `צריך בדיוק ${minLength} ספרות` : ''
    const requiredErrors = (required && !value && `שדה זה הינו חובה`)
    setNumericDigitsErrors(digitErrors || lengthErrors || requiredErrors);
  }, [minLength, required]);

  return [handleAndValidateNumericDigits, numericDigitsErrors]
};
