import {useCallback, useState} from 'react'

export default function useEmailValidator() {
  const [emailErrors, setEmailErrors] = useState('')
  const [email, setEmail] = useState()
  const handleAndValidateEmail = useCallback(({target:{value}}) => {
    setEmailErrors((value && /\S+@\S+\.\S+/.test(value)) ? '' : 'אימייל אינו תקין')
    setEmail(value)
  }, [])

  return [handleAndValidateEmail, emailErrors, email]
};
