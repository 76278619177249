import {Check} from '@mui/icons-material'
import {ListItemIcon} from '@mui/material'
import {getTenant} from 'Helpers/tenants'
import useRedirect from 'Hooks/useRedirect'
import {useMemo} from 'react'
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PublicIcon from '@mui/icons-material/Public';
import {AIRGAP_AUTH} from 'Redux/consts'
import {stringAvatar} from './appBarUtils'
import styles from './index.module.scss'
import getConfig from 'Config/config'

const ORDERS = 'ORDERS'
const CHECKLINES = 'CHECKLINES'
const TICKETS = 'TICKETS'
const SWAGGER = 'SWAGGER'
const MAINSCREEN = 'MAINSCREEN'
const ADMIN = 'ADMIN'
const SYSTABLES = 'SYSTABLES'

const pages = [
  {
    id: ORDERS,
    display: 'הזמנות',
    url: '/orders',
  }, {
    id: CHECKLINES,
    display: 'בדיקות קו',
    url: '/checklines',
  }, {
    id: TICKETS,
    display: 'תקלות',
    url: '/tickets',
  },

  ...(getConfig().hasSwagger ? [{
    id: SWAGGER,
    display: 'SWAGGER',
    url: '/SWAGGER',
  }] : [])


]

function ResponsiveAppBar({loggedInUser, isConnected, authorizationMethod, isMockupMode, setIsMockupMode}) {
  const tenantInfo = getTenant()
  const redirect = useRedirect()
  const settings = useMemo(() => [{
    id: MAINSCREEN,
    display: 'מסך ראשי',
    url: '/',
  }, ...(authorizationMethod === AIRGAP_AUTH && ['admin', 'god'].includes(loggedInUser?.geobez?.toLowerCase()) ? [{
    id: ADMIN,
    display: 'ניהול משתמשים',
    url: '/admin',
  }] : []),
    ...(['god'].includes(loggedInUser?.geobez?.toLowerCase()) ? [{
      id: SYSTABLES,
      display: 'טבלאות מערכת',
      url: '/systables',
    }] : [])
  ], [authorizationMethod, loggedInUser?.geobez])

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageClicked = (pageId) => {
    const page = pages.find(({id}) => id === pageId)
    redirect(page.url)
  }

  const handleUserMenu = (settingId) => {
    const setting = settings.find(({id}) => id === settingId)
    setAnchorElUser(null)
    if (setting) {
      redirect(setting.url)
    }
  }

  const extraParams = !getConfig().isProduction ? {color:"secondary", enableColorOnDark: true} : {}
  return (
    <AppBar position="static" {...extraParams}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <PublicIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
          <Typography
            variant="h6"
            noWrap
            component="div"
            href="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              maxWidth: '30vw',
            }}
          >
            <span className={styles.ellipsis}>{tenantInfo?.displayName}</span>
          </Typography>

          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            {isConnected && <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
              {pages.map(({id,display}) => (
                <MenuItem key={id} onClick={() => handlePageClicked(id)}>
                  <Typography textAlign="center">{display}</Typography>
                </MenuItem>
                ))}
            </Menu>}
          </Box>
          <PublicIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
          <Typography
            variant="h5"
            noWrap
            component="div"
            href=""
            sx={{
              mr: 2,
              display: {xs: 'flex', md: 'none'},
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          ><span className={styles.ellipsis}>{tenantInfo?.displayName}</span>
          </Typography>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
            {isConnected && pages.map(({id, display}) => (
              <Button
                key={id}
                onClick={() => handlePageClicked(id)}
                className={styles.clickableLinks}
              >
                {display}
              </Button>
            ))}
          </Box>

          <Box sx={{flexGrow: 0}}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                <Avatar {...stringAvatar(loggedInUser?.displayName)}/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleUserMenu}
            >
              {settings.map(({id,display}) => (
                <MenuItem key={id} onClick={() => handleUserMenu(id)}>
                  <Typography textAlign="center">{display}</Typography>
                </MenuItem>
              ))}
              {!getConfig().isProduction &&
                <MenuItem onClick={() => setIsMockupMode(!isMockupMode)}>
                  מצב Mockup
                  <ListItemIcon>
                    {isMockupMode && <Check/>}
                  </ListItemIcon>
                </MenuItem>
              }
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
