import apiServer from 'Api/Backend'
import {loadTenantUsers, loadTenantUsersResult, publishChunk, setIsUserEditorOpen, setLoadTenantUsersIsLoading, setSaveTenantUsersIsLoading} from 'Containers/AdminConsole/actions'
import {DELETE_TENANT_USER, LOAD_TENANT_USERS, UPDATE_TENANT_USER, UPLOAD_ADDRESSES_XLSX} from 'Containers/AdminConsole/consts'
import {getTenantUsers, getUserBeingEdited} from 'Containers/AdminConsole/selectors'
import {loadEssentialStructures} from 'Containers/AppGeneral/actions'
import {toastr} from 'Helpers/toastr'
import {takeEvery, put, call, select} from 'redux-saga/effects'
import sha256 from 'crypto-js/sha256'
import encHex from 'crypto-js/enc-hex'
import {getCitiesLength, getStreetsLength} from 'Containers/AppGeneral/selectors'

function *loadTenantUsersSaga() {
    yield put(setLoadTenantUsersIsLoading(true))
    try {
        const result = yield call(apiServer.get, 'admin/LoadTenantUsers', {})
        yield put(loadTenantUsersResult(result))
    } catch (e) {
        console.error(e)
        toastr.error('שגיאה בטעינת משתמשים')
    }
    yield put(setLoadTenantUsersIsLoading(false))
}
function *updateTenantUserSaga({email, user}) {
    yield put(setSaveTenantUsersIsLoading(true))
    try {
        const tenantUsers = yield select(getTenantUsers)
        const userBeingEdited = yield select(getUserBeingEdited)
        const isLastEditedWasNewUser = !(userBeingEdited)
        if (isLastEditedWasNewUser && tenantUsers.users[email]) {
            yield put(setIsUserEditorOpen(false))
            toastr.error('אין אפשרות לדרוס אימייל שכבר קיים במערכת')
            throw new Error('אין אפשרות לדרוס אימייל שכבר קיים במערכת')
        }
        const password = user?.password ? sha256(user.password).toString(encHex) : tenantUsers?.users?.[email].password
        const newUsers = {...tenantUsers.users, [email]:{...user, password}}
        const result = yield call(apiServer.post, 'admin/SaveTenantUsers', newUsers)
        console.log('Saving users result',{result})
        yield put(loadTenantUsers())
    } catch (e) {
        console.error(e)
        toastr.error('שגיאה בשמירת משתמש')
    }
    yield put(setIsUserEditorOpen(false))
    yield put(setSaveTenantUsersIsLoading(false))
}
function *deleteTenantUserSaga({email}) {
    yield put(setSaveTenantUsersIsLoading(true))
    try {
        const tenantUsers = yield select(getTenantUsers)
        const copyUsers = {...tenantUsers.users}
        delete copyUsers[email]
        const result = yield call(apiServer.post, 'admin/SaveTenantUsers', copyUsers)
        console.log('Saving users (from delete user) result',{result})
        yield put(loadTenantUsers())
    } catch (e) {
        console.error(e)
        toastr.error('שגיאה במחיקת משתמש')
    }
    yield put(setIsUserEditorOpen(false))
    yield put(setSaveTenantUsersIsLoading(false))
}
function *uploadAddressesXlsxSaga({addressesXlsx, progressUpdates, allowReduction,dispatch}) {
    try {
        let origCitiesCount = yield select(getCitiesLength)
        let origStreetsCount = yield select(getStreetsLength)
        if (origCitiesCount < 10) origCitiesCount = undefined
        if (origStreetsCount < 100) origStreetsCount = undefined
        const publishChunkCb = async (str,code) => {
            await dispatch(publishChunk(str,code))
        }
        yield call(publishChunkCb,null)
        const filename = yield call(apiServer.postWithFormData, 'admin/UploadAddressesXlsx', {addressesXlsx}, progressUpdates)
        yield call(progressUpdates,null)
        yield call(apiServer.postWithStreamResponse, 'admin/ProcessAddressesXlsx', {filename, allowReduction, origCitiesCount, origStreetsCount}, publishChunkCb)
        yield put(loadEssentialStructures())
    } catch (e) {
        console.error(e)

    }
}

export default function* AdminConsoleSaga() {
    yield takeEvery(LOAD_TENANT_USERS, loadTenantUsersSaga)
    yield takeEvery(UPDATE_TENANT_USER, updateTenantUserSaga)
    yield takeEvery(DELETE_TENANT_USER, deleteTenantUserSaga)
    yield takeEvery(UPLOAD_ADDRESSES_XLSX, uploadAddressesXlsxSaga)
}
