import Typography from '@mui/joy/Typography'
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import Label from 'Components/Generic/Label'
import {formatBezeqDate} from 'Components/Tickets/ticketsUtils'
import {SERVICE_ORDER_STATUS_CODE, TASK_CLOSE_STATE, TASK_STATUS_CODE, TASK_TYPE_CODE} from 'Containers/AppGeneral/consts'
import {TICKET_RETRIEVALS} from 'Containers/Tickets/consts'
import * as React from 'react'
import {useState} from 'react'
import styles from './TicketRetrival.module.scss'

export default function TicketRetrievals({singleTicket} = {}) {
  const [expanded, setExpanded] = useState('');

  const ticketRetrievals = singleTicket?.[TICKET_RETRIEVALS] || []

  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  return <div>
      {ticketRetrievals.map((singleRetrieval, index) => {
        const {
          serviceOrderStatusCode,
          taskStatusCode,
          taskCloseState,
          taskCloseReason,
          serviceOrderTypeDesc,
          taskTypeCode,
          taskTypeDesc,
          actualTimeArrival,
          slaTo,
        } = singleRetrieval

        return <Accordion key={`retrieval${index}-key`} expanded={expanded === `retrieval${index}`} onChange={handleChange(`retrieval${index}`)}>
          <AccordionSummary aria-controls={`retrieval${index}-content`} id={`retrieval${index}-header`}>
            <Typography>עדכון מתאריך {formatBezeqDate(singleRetrieval?.messageSendTimeReturn)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.retrievalContainer}>
              <div className={styles.inARow}>
                {serviceOrderStatusCode && <Label variant="filled" label="סטטוס קריאת שרות" value={SERVICE_ORDER_STATUS_CODE[serviceOrderStatusCode]} inputProps={{readOnly: true}}/>}
                {taskStatusCode && <Label variant="filled" label="סטטוס משימה" value={TASK_STATUS_CODE[taskStatusCode]} inputProps={{readOnly: true}}/>}
                {taskCloseState && <Label variant="filled" label="סטטוס סגירת משימה" value={TASK_CLOSE_STATE[taskCloseState]} inputProps={{readOnly: true}}/>}
              </div>
              {taskCloseReason && <Label variant="filled" label="סיבת סגירה" value={taskCloseReason} inputProps={{readOnly: true}}/>}
              {serviceOrderTypeDesc && <Label variant="filled" label="תאור סוג קריאת שרות" value={serviceOrderTypeDesc} inputProps={{readOnly: true}}/>}
              {taskTypeCode && <Label variant="filled" label="קוד סוג משימה" value={TASK_TYPE_CODE[taskTypeCode]} inputProps={{readOnly: true}}/>}
              {taskTypeDesc && <Label variant="filled" label="תאור סוג משימה" value={taskTypeDesc} inputProps={{readOnly: true}}/>}
              {actualTimeArrival && <Label variant="filled" label="זמן הגעה בפועל" value={formatBezeqDate(actualTimeArrival)} inputProps={{readOnly: true}}/>}
              {slaTo && <Label variant="filled" label="S.L.A" value={formatBezeqDate(slaTo)} inputProps={{readOnly: true}}/>}
            </div>
          </AccordionDetails>
        </Accordion>
      })}
  </div>
}
