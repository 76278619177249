import EditTicket from 'Components/Tickets/EditTicket/EditTicket'
import {loadSingleTicket, setEditTicketFormValues} from 'Containers/Tickets/actions'
import {getEditTicketFormValues, getLoadSingleTicketIsLoading, getSingleTicket} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    loadSingleTicket: ticketId => dispatch(loadSingleTicket(ticketId)),
    setEditTicketFormValues: formValues => dispatch(setEditTicketFormValues(formValues)),
  }
};
const mapStateToProps = (state,ownProps) => {
  const {match:{params:{ticketId}}} = ownProps
  return {
    ticketId: Number(ticketId),
    loadSingleTicketIsLoading: getLoadSingleTicketIsLoading(state),
    singleTicket: getSingleTicket(state),
    editTicketFormValues: getEditTicketFormValues(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTicket)
