import styles from './FourZeroFour.module.scss';

const FourZeroFour = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
      </div>
      <div className={styles.content}>
        <div>
          <h1>כתובת שגויה</h1>
        </div>
      </div>
      <div className={styles.footer}>כל הזכויות שמורות © {((new Date()).getYear()+1900)} גיאוסל מערכות בע"מ</div>
    </div>
  )
};

export default FourZeroFour;
