import {Chip} from '@mui/material'
import {FacebookCircularProgress} from 'Components/Generic/Spinner'
import Skeleton from 'Components/Skeleton'
import EditTicketPanel from 'Containers/Tickets/EditTicketPanel/EditTicketPanel'
import {useEffect, useMemo, useState} from 'react'
import styles from './EditTicket.module.scss'

const EditTicket = ({ticketId, loadSingleTicket, singleTicket = {}, loadSingleTicketIsLoading, editTicketFormValues, setEditTicketFormValues}) => {

  const [editTicketComponentKey, setEditTicketComponentKey] = useState(0)

  // clear/reload EditTicketPanel on a freshly loaded ticket
  useMemo(() => {
    setEditTicketComponentKey(prev => prev + 1)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [singleTicket, editTicketFormValues, setEditTicketComponentKey])

  useEffect(() => {
    if (ticketId) {
      loadSingleTicket(ticketId)
    }
  }, [ticketId, loadSingleTicket])


  const {
    timeIntervals,
    ticketRetrievals,
  } = singleTicket

  const {
    actualTimeArrival,
    serviceOrderStatusCode,
    taskStatusCode
  } = (ticketRetrievals?.[0] || {})

  const ticketClosed = serviceOrderStatusCode === 3 || taskStatusCode === 3 // gizim: change to enum
  return <Skeleton className={styles.ticketPageContainer}>
    {loadSingleTicketIsLoading ? <div className={styles.spinnerContainer}><FacebookCircularProgress/></div>
      :
      <>
        <div className={styles.ticketHeaderContainer}>
          <h3>תקלה מס׳ {ticketId} &nbsp;</h3>
          {ticketClosed && <Chip label="תקלה סגורה" color="primary" />}
          {!ticketClosed && timeIntervals && <Chip label="יש לבחור מועד טכנאי" color="primary" />}
          {!ticketClosed && actualTimeArrival && <Chip label="טכנאי משובץ" color="success" />}
        </div>
        <EditTicketPanel ticketId={ticketId} key={`editTicket${editTicketComponentKey}`} formValues={editTicketFormValues} setFormValues={setEditTicketFormValues} singleTicket={singleTicket}/>
      </>
    }
  </Skeleton>
}

export default EditTicket
