import {
    setIsConnected,
} from '../actions';
import {connect} from 'react-redux';
import LoginLogoutBox from "../../../Components/Auth/TenantPage/LoginLogoutBox";
import {createStructuredSelector} from "reselect";

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => {
    return {
        setIsConnected: (isConnected) => dispatch(setIsConnected(isConnected)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLogoutBox)
