import {
    CHECK_LINE_OUTCOME_INFO,
    CHECK_LINE_REQUEST_INFO, CHECK_LINES_KEYWORD, CREATE_CHECK_LINE_IS_LOADING,
    CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID, CREATE_CHECK_LINE_OUTCOME_WS5_RESULT,
    CREATE_CHECK_LINE_REQUEST_IS_LOADING,
    CREATE_CHECK_LINE_REQUEST_WS4_RESULT, SET_CHECK_LINES_KEYWORD, SET_CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID,
    SET_CREATE_CHECK_LINE_REQUEST_IS_LOADING
} from 'Containers/CheckLines/consts'
import {HAS_MORE, LOAD_CHECK_LINES_IS_LOADING, LOAD_CHECK_LINES_RESULT, CHECK_LINES_TABLE, SET_LOAD_CHECK_LINES_IS_LOADING, TABLE_DATA} from 'Containers/CheckLines/consts'
import {fromJS, merge} from "immutable";

const emptyCheckLinesTable = {
    [HAS_MORE]: true, // make default for 1st time loading
    [TABLE_DATA]: {},
}

const initialState = fromJS({
    [CHECK_LINE_REQUEST_INFO]: null,
    [CREATE_CHECK_LINE_REQUEST_IS_LOADING]: false,
    [CHECK_LINES_TABLE]: emptyCheckLinesTable,
    [LOAD_CHECK_LINES_IS_LOADING]: false,
    [CHECK_LINE_OUTCOME_INFO]: null,
    [CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID]: false,
    [CHECK_LINES_KEYWORD]: '',
});

export default function CheckLinesReducer(state = initialState, action){
    switch (action.type) {
        case CREATE_CHECK_LINE_REQUEST_WS4_RESULT:
            return state.set(CHECK_LINE_REQUEST_INFO, fromJS(action.result))
        case SET_CREATE_CHECK_LINE_REQUEST_IS_LOADING:
            return state.set(CREATE_CHECK_LINE_REQUEST_IS_LOADING, action.isLoading)
        case LOAD_CHECK_LINES_RESULT:
            return state.updateIn([CHECK_LINES_TABLE, TABLE_DATA],
              checkLines => merge(checkLines, fromJS(action.result.checkLines)))
              .setIn([CHECK_LINES_TABLE, HAS_MORE],action.result.hasMore)
        case SET_LOAD_CHECK_LINES_IS_LOADING:
            return state.set(LOAD_CHECK_LINES_IS_LOADING, action.isLoading)
        case CREATE_CHECK_LINE_OUTCOME_WS5_RESULT:
            return state.updateIn([CHECK_LINES_TABLE, TABLE_DATA, String(action.result.id)],
              (row) => {
                  const myRow = row.toJS()
                  return fromJS({...myRow, response: action.result.response})
              })
        case SET_CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID:
            return state.set(CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID, action.isLoadingAndId && Number(action.isLoadingAndId))
        case SET_CHECK_LINES_KEYWORD:
            return state.set(CHECK_LINES_KEYWORD, action.keyword).set(CHECK_LINES_TABLE,fromJS(emptyCheckLinesTable))
        default:
            return state
    }
}
