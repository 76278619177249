import {Button} from '@mui/material'
import classes from 'classnames'
import Mirror from 'Components/Generic/Mirror'
import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import styles from './BasicModal.module.scss'

export default function BasicModal({isOpen, setIsOpen, openModalButtonText, buttonProps, title, content, footer, className, isMirror = true, hideBackdrop = false}) {
  return (
    <React.Fragment>
      {openModalButtonText && <Button {...buttonProps} onClick={() => setIsOpen(true)}>
        {openModalButtonText}
      </Button>}
      {isOpen && <Modal
        className={classes(styles.modalContainer, className)}
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        hideBackdrop={hideBackdrop}
      >
        <Sheet
          className={styles.modalSheet}
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.body',
            }}
          />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            {title}
          </Typography>
          <div className={classes(styles.modalContent, styles.scrollableContainer)}>
            {isMirror ? <Mirror>
              {content}
            </Mirror> : <>{content}</>}
          </div>
          {footer && <div>
            {footer}
          </div>}
        </Sheet>
      </Modal>}
    </React.Fragment>
  );
}
