import UserEditor from 'Components/AdminConsole/UserEditor/UserEditor'
import {deleteTenantUser, setIsUserEditorOpen, updateTenantUser} from 'Containers/AdminConsole/actions'
import {getIsUserEditorOpen, getSaveTenantUsersIsLoading, getUserBeingEdited} from 'Containers/AdminConsole/selectors'
import {getLoggedInUser} from 'Containers/Auth/selectors'
import {connect} from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    setIsUserEditorOpen: isOpen => dispatch(setIsUserEditorOpen(isOpen)),
    updateTenantUser: (email, user) => dispatch(updateTenantUser(email, user)),
    deleteTenantUser: email => dispatch(deleteTenantUser(email)),
  }
};
const mapStateToProps = (state,) => {
  return {
    userBeingEdited: getUserBeingEdited(state),
    isUserEditorOpen: getIsUserEditorOpen(state),
    saveTenantUsersIsLoading: getSaveTenantUsersIsLoading(state),
    loggedInUser: getLoggedInUser(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditor)
