import CircularProgress from '@mui/material/CircularProgress'
import * as React from 'react'
import {Button} from '@mui/material'
import styles from './Spinner.module.scss'

const SpinnerButton = ({isLoading, variant, color, disabled, children, ...props}) => {
  return <div className={styles.spinnerContent}>
    <Button
      variant={variant || "contained"}
      color={color || "primary"}
      disabled={disabled || isLoading}
      {...props}
    >
      {children}
    </Button>
    {isLoading && (
      <CircularProgress
        size={24}
        className={styles.progress}
      />
    )}
  </div>
}
export default SpinnerButton
