import {connect} from "react-redux";
import TenantPage from "../../../Components/Auth/TenantPage/TenantPage";
import {getIsConnected} from '../selectors'

const mapStateToProps = (state,) => {
  const isConnected = getIsConnected(state)

  return {
    isConnected,
  }
};

export default connect(mapStateToProps,null)(TenantPage);
