import OrderRetrievals from 'Components/Orders/OrderRetrievals/OrderRetreivals'
import {connect} from 'react-redux';

const mapDispatchToProps = () => {
  return {
  }
};
const mapStateToProps = () => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRetrievals)
