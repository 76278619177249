import CreateCheckLine from 'Components/CheckLines/CreateCheckLine/CreateCheckLine'
import {createCheckLineRequestWs4} from 'Containers/CheckLines/actions'
import {getCheckLineRequestInfo, getCheckLineRequestIsLoading} from 'Containers/CheckLines/selectors'
import {getCreateTicketFormValues} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    createCheckLineRequestWs4: (request) => dispatch(createCheckLineRequestWs4(request))
  }
};
const mapStateToProps = (state,ownProps) => {
  const {match:{params:{orderId}}} = ownProps

  return {
    checkLineRequestIsLoading: getCheckLineRequestIsLoading(state),
    checkLineRequestInfo: getCheckLineRequestInfo(state),
    orderId,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCheckLine)
