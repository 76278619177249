import {connect} from "react-redux";
import Routes from "../../Components/Routes";
import {createStructuredSelector} from "reselect";

const mapStateToProps = createStructuredSelector({
})

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
