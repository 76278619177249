import {CHECK_LINE_OUTCOME_INFO, CHECK_LINE_REQUEST_INFO, CHECK_LINES_KEYWORD, CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID, CREATE_CHECK_LINE_REQUEST_IS_LOADING} from 'Containers/CheckLines/consts'
import {LOAD_CHECK_LINES_IS_LOADING, CHECK_LINES_TABLE} from 'Containers/CheckLines/consts'
import {CHECK_LINES} from 'Redux/consts'
import {createSelector} from 'reselect';

export const getCheckLineRequestIsLoading = createSelector(
    state => state.getIn([CHECK_LINES, CREATE_CHECK_LINE_REQUEST_IS_LOADING]),
    isLoading => isLoading,
)
export const getCheckLineRequestInfo = createSelector(
  state => state.getIn([CHECK_LINES, CHECK_LINE_REQUEST_INFO]),
  createCheckLineResult => createCheckLineResult?.toJS(),
)

export const getCheckLinesTable = createSelector(
  state => state.getIn([CHECK_LINES,CHECK_LINES_TABLE]),
  checkLinesTable => checkLinesTable?.toJS(),
)
export const getLoadCheckLinesIsLoading = state => state.getIn([CHECK_LINES,LOAD_CHECK_LINES_IS_LOADING])

export const getCheckLineOutcomeIsLoadingAndId = createSelector(
  state => state.getIn([CHECK_LINES, CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID]),
  isLoading => isLoading,
)
export const getCheckLineOutcomeInfo = createSelector(
  state => state.getIn([CHECK_LINES, CHECK_LINE_OUTCOME_INFO]),
  info => info?.toJS(),
)

export const getCheckLinesKeyword = createSelector(
  state => state.getIn([CHECK_LINES,  CHECK_LINES_KEYWORD]),
  keyword => keyword,
)
