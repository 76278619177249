import {FormControl, InputLabel} from '@mui/material'
import styles from './Label.module.scss'

const Label = ({value, children, label}) => {
  return <FormControl variant="filled">
    <InputLabel shrink={true} htmlFor="component-filled">{label}</InputLabel>
    <div className={styles.label}>
        {value || children}
    </div>
  </FormControl>
}
export default Label
