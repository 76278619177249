import LocateByGis from 'Components/Orders/LocateByGis/LocateByGis'
import {getCities, getStreets} from 'Containers/AppGeneral/selectors'
import {locateByGisWs16, setLocateByGisFormValues} from 'Containers/Orders/actions'
import {getLocateByGisFormValues, getLocateByGisIsLoading} from 'Containers/Orders/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    doLocateByGis: (formData) => dispatch(locateByGisWs16(formData)),
    setLocateByGisFormValues: (formData) => dispatch(setLocateByGisFormValues(formData)),
  }
};
const mapStateToProps = (state,) => {
  return {
    cities: getCities(state),
    streets: getStreets(state),
    locateByGisFormValues: getLocateByGisFormValues(state),
    locateByGisIsLoading: getLocateByGisIsLoading(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocateByGis)
