import {axiosInstance} from 'Api'
import AppBar from 'Containers/AppBar'
import {getTenant, getToken} from 'Helpers/tenants'
import {toastr} from 'Helpers/toastr'
import {useEffect} from 'react'
import scriptjs from 'scriptjs'
import styles from './index.module.scss'
import getConfig from 'Config/config'

const Index = () => {
  useEffect(() => {
    const tenantId = getTenant()?.id
    const token = getToken()
    if (tenantId && token) {
      axiosInstance.get(`${getConfig().getSwaggerServer()}v1/swagger.json?selectedTenant=${tenantId}&token=${token}`)
        .then(result => {
          const {data} = result
          if (data?.paths) {
            Object.keys(data.paths).forEach(key => {
              let value = data.paths[key]
              let supplementPrefix = key.indexOf('?') > -1 ? '&' : '?'
              delete data.paths[key]
              data.paths[`${key}${supplementPrefix}selectedTenant=${tenantId}`] = value
            });
          }

          if (!data?.components) data.components = {}
          const securitySchemes = data.components?.securitySchemes || {}
          securitySchemes.bearerAuth = {
            type: 'http',
            scheme: 'bearer',
          }
          data.components.securitySchemes = securitySchemes
          if (!data?.security) data.security = []
          data.security.push({bearerAuth: []})

          data.servers = [
            {
              url: getConfig().backendServer,
              description: 'Bezeq Dev Server',
            }]

          scriptjs(`${getConfig().getSwaggerServer()}swagger-ui-bundle.js`, () => {
            const ui = window.SwaggerUIBundle({
              dom_id: '#swagger',
              spec: data,
              onComplete: function () {
                ui.preauthorizeApiKey("bearerAuth", token);
              }
            })
          })
        }).catch((e) => {
        console.error(e)
        toastr.error('error loading swagger.json could be token expiration');
      })
    }
  }, [])

  return <div className={styles.contentMain}>
    <div className={styles.appBar}>
      <AppBar/>
    </div>
    <div className={styles.swaggerContent}>
      <link rel="stylesheet" href={`${getConfig().getSwaggerServer()}swagger-ui.css`}/>
      <div id="swagger">
        <div className={styles.waiting}>Waiting for swagger...</div>
      </div>
    </div>
  </div>
}
export default Index
