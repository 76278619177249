import AdminConsole from 'Components/AdminConsole/AdminConsole'
import {loadTenantUsers, setIsUserEditorOpen, setUserBeingEdited, updateTenantUser} from 'Containers/AdminConsole/actions'
import {getIsUserEditorOpen, getUserBeingEdited} from 'Containers/AdminConsole/selectors'
import {connect} from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    loadTenantUsers: () => dispatch(loadTenantUsers()),
    setIsUserEditorOpen: isOpen => dispatch(setIsUserEditorOpen(isOpen)),
    updateTenantUser: user => dispatch(updateTenantUser(user)),
    setUserBeingEdited: userAndEmail => dispatch(setUserBeingEdited(userAndEmail)),
  }
};
const mapStateToProps = (state,) => {
  return {
    isUserEditorOpen: getIsUserEditorOpen(state),
    userBeingEdited: getUserBeingEdited(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminConsole)
