import getConfig from 'Config/config'
import {setIsMockupMode} from 'Containers/AppGeneral/actions'
import {getIsMockupMode} from 'Containers/AppGeneral/selectors'
import {connect} from 'react-redux'
import AppBar from 'Components/AppBar'
import {getIsConnected, getLoggedInUser} from 'Containers/Auth/selectors'

const mapStateToProps = (state,) => {
  const isConnected = getIsConnected(state)
  const loggedInUser = getLoggedInUser(state)
  const authorizationMethod = getConfig().authorizationMethod
  const isMockupMode = getIsMockupMode(state)

  return {
    isConnected,
    loggedInUser,
    authorizationMethod,
    isMockupMode,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setIsMockupMode: value => dispatch(setIsMockupMode(value))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AppBar);
