import CreateOrder from 'Components/Orders/CreateOrder/CreateOrder'
import {locateByGisWs16Result, locateCustomerWs1Result, setCreateOrderFormValues, setLocateByGisFormValues} from 'Containers/Orders/actions'
import {getCreateOrderFormValues, getCustomerInfraInfo, getLocateByGisFormValues, getLocateByGisIsLoading, getLocateCustomerInfraIsLoading, getLocatedBoxInfo} from 'Containers/Orders/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    setCreateOrderFormValues: formValues => dispatch(setCreateOrderFormValues(formValues)),
    setLocateCustomerWs1Result: result => dispatch(locateCustomerWs1Result(result)),
    setLocateByGisWs16Result: result => dispatch(locateByGisWs16Result(result)),
    setLocateByGisFormValues: formValues => dispatch(setLocateByGisFormValues(formValues)),
  }
}

const mapStateToProps = (state) => {
  return {
    locateCustomerInfraIsLoading: getLocateCustomerInfraIsLoading(state),
    customerInfraInfo: getCustomerInfraInfo(state),
    createOrderFormValues: getCreateOrderFormValues(state),
    locateByGisFormValues: getLocateByGisFormValues(state),
    locateByGisIsLoading: getLocateByGisIsLoading(state),
    locatedBoxInfo: getLocatedBoxInfo(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
