import NewIcon from '@mui/icons-material/AddBox'
import {debounce, TextField} from '@mui/material'
import Button from '@mui/material/Button'
import Mirror from 'Components/Generic/Mirror'
import Skeleton from 'Components/Skeleton'
import CheckLinesTable from 'Containers/CheckLines/CheckLinesTable/CheckLinesTable'
import useRedirect from 'Hooks/useRedirect'
import * as React from 'react'
import styles from './CheckLines.module.scss'

const CheckLines = ({checkLinesKeyword, setCheckLinesKeyword}) => {
  const redirect = useRedirect()

  const _setCheckLinesKeyword = debounce(change => {
    setCheckLinesKeyword(change);
  }, 250);

  return <Skeleton className={styles.container}>
    <h2>רשימת בדיקות קו </h2>
    <div className={styles.topButtons}>
      <Button variant={"contained"}
              color={"primary"}
              startIcon={<NewIcon/>} onClick={() => {
        redirect('/checklines/new')
      }}>בדיקת קו חדשה</Button>
      <Mirror>
        <TextField
          label="חיפוש"
          type="search"
          variant="standard"
          onChange={({target:{value}}) => _setCheckLinesKeyword(value)}
          defaultValue={checkLinesKeyword}
        />
      </Mirror>
    </div>
    <CheckLinesTable/>
  </Skeleton>
}
export default CheckLines
