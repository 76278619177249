import EditOrder from 'Components/Orders/EditOrder/EditOrder'
import {loadSingleOrder, setEditOrderFormValues} from 'Containers/Orders/actions'
import {getEditOrderFormValues, getLoadSingleOrderIsLoading, getSingleOrder} from 'Containers/Orders/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    loadSingleOrder: orderId => dispatch(loadSingleOrder(orderId)),
    setEditOrderFormValues: formValues => dispatch(setEditOrderFormValues(formValues)),
  }
};
const mapStateToProps = (state,ownProps) => {
  const {match:{params:{orderId}}} = ownProps
  return {
    orderId: Number(orderId),
    loadSingleOrderIsLoading: getLoadSingleOrderIsLoading(state),
    singleOrder: getSingleOrder(state),
    editOrderFormValues: getEditOrderFormValues(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder)
