import DeleteIcon from '@mui/icons-material/Delete'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {Alert, Checkbox, FormControlLabel} from '@mui/material'
import LinearProgressWithLabel from 'Components/Generic/LinearProgressWithLabel'
import Button from '@mui/material/Button'
import {useState} from 'react'
import styles from './SysTables.module.scss'
import Skeleton from 'Components/Skeleton'
import * as React from 'react'

export default function SysTables({uploadAddressesXlsx, serverProgress, resetProgress}) {

  const fileRef = React.useRef()
  const acceptedFormats = '.xlsx'

  const [selectedFile, setSelectedFile] = useState()
  const [progress, setProgress] = useState(0)
  const [allowReduction, setAllowReduction] = useState(false)

  const progressUpdates = progressEvent => {
    setProgress(progressEvent?.progress)
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event?.target?.files?.[0])
  };

  const onUpload = () => {
    uploadAddressesXlsx(selectedFile, progressUpdates, allowReduction)
  };

  const onClear = () => {
    setSelectedFile(undefined)
    fileRef.current.value = null
    resetProgress()
  }
  return <Skeleton className={styles.container}>
    <h2>העלאת קובץ כתובות</h2>
    <div className={styles.topButtons}>
      <input
        ref={fileRef}
        hidden
        type="file"
        accept={acceptedFormats}
        onChange={handleFileSelect}
      />
      <Button
        variant="contained"
        onClick={() => fileRef.current?.click()}
        startIcon={<AttachFileIcon/>}
      >
        בחר קובץ xlsx
      </Button>
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={onClear}
        disabled={!selectedFile}
        startIcon={<DeleteIcon/>}>נקה בחירה</Button>
    </div>
    <div className={styles.middle}>
      {selectedFile?.name}
    </div>
    <div className={styles.checkbox}>
      <FormControlLabel label="אפשר הפחתה במספר הכתובות"
                        name="allowReduction"
                        className={styles.checkBoxStyleAssurer}
                        onChange={(e,v) => setAllowReduction(v)}
                        control={<Checkbox/>}
      />
    </div>
    <div className={styles.bottomButtons}>
      <Button
        color="primary"
        disabled={!selectedFile || !!progress || !!serverProgress?.percentage}
        onClick={onUpload}
        startIcon={<FileUploadIcon/>}
      >
        Upload
      </Button>
      {!!progress && <LinearProgressWithLabel value={progress * 100}/>}
      {!!(serverProgress?.percentage) &&
        <div>
          <LinearProgressWithLabel value={serverProgress.percentage * 100}/>
          <Alert severity={serverProgress?.error ? "error" : "info"}>{serverProgress.msg}</Alert>
        </div>
      }
    </div>
  </Skeleton>
}

