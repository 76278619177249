export function formatTimeSlotRange(item) {
  if (!item) return null
  return `${formatBezeqDate(item.estimatedTimeFrom)} עד ${formatBezeqDate(item.estimatedTimeTo)}`
}

export function formatBezeqDate(str){
  const YYYY = str.substring(0,4)
  const MM = str.substring(4,6)
  const DD = str.substring(6,8)
  if (str.length>10) {
    const hh = str.substring(8, 10)
    const mm = str.substring(10, 12)
    return `${hh}:${mm} ${DD}/${MM}/${YYYY}`
  } else {
    return `${DD}/${MM}/${YYYY}`
  }
}

export function formatBackendDate(dateString) {
  const date = new Date(dateString);

  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');
  const DD = String(date.getDate()).padStart(2, '0');
  const MM = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const YYYY = date.getFullYear();

  return `${hh}:${mm} ${DD}/${MM}/${YYYY}`;
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
