import CloseIcon from '@mui/icons-material/Close'
import {Button} from '@mui/material'
import CheckLineResultInfo from 'Components/CheckLines/CheckLineResultInfo/CheckLineResultInfo'
import BasicModal from 'Components/Generic/BasicModal'
import InfiniteScrollTable from 'Components/InfiniteScrollTable/InfiniteScrollTable'
import CheckLinesContainerHolder from 'Components/CheckLines/CheckLinesTable/CheckLinesContainerHolder'
import {TABLE_DATA} from 'Containers/CheckLines/consts'
import React, {useCallback, useEffect, useState} from 'react'
import styles from './index.module.scss'

const PAGE = 50

const CheckLinesTable = ({loadCheckLines, checkLinesTable, loadCheckLinesIsLoading, checkLineOutcomeIsLoadingAndId, createCheckLineOutcomeWs5, checkLinesKeyword}) => {
  const {hasMore} = checkLinesTable
  const tableData = checkLinesTable?.[TABLE_DATA]
  const [displayedResult, setDisplayedResult] = useState(false)

  useEffect(() => {
    const length = Object.keys(tableData).length
    if (length <= 1 && hasMore) {
      loadCheckLines(0, PAGE, checkLinesKeyword)
    }
  }, [tableData, loadCheckLines, checkLinesKeyword])

  const loadCheckLinesWithOffset = useCallback(() => {
    const offset = Object.keys(tableData).length
    loadCheckLines(offset, PAGE, checkLinesKeyword)
  }, [loadCheckLines, tableData, checkLinesKeyword])

  const handleClick = useCallback((e, id) => {
    if (tableData[id]?.response) {
      setDisplayedResult(tableData[id])
    } else {
      createCheckLineOutcomeWs5(id)
    }
  }, [setDisplayedResult, tableData, createCheckLineOutcomeWs5])


  return <>
    <BasicModal
      isOpen={!!displayedResult}
      setIsOpen={setDisplayedResult}
      title={'תוצאות בדיקת קו'}
      content={<CheckLineResultInfo checkLineResultInfo={displayedResult} />}
      footer={<div className={styles.footer}>
        <Button onClick={() => setDisplayedResult(false)}
                variant="contained"
                color="primary"
                type="button"
                startIcon={<CloseIcon/>}
        >סגור</Button>
      </div>}
    />
    <InfiniteScrollTable
      isLoading={loadCheckLinesIsLoading}
      hasMore={hasMore}
      loadItems={loadCheckLinesWithOffset}
      TableContainer={CheckLinesContainerHolder}
      tableProps={{tableData, handleClick, checkLineOutcomeIsLoadingAndId}}
    />
  </>
}
export default CheckLinesTable
