import {
    DELETE_TENANT_USER,
    LOAD_TENANT_USERS,
    LOAD_TENANT_USERS_RESULT, PUBLISH_CHUNK, RESET_PROGRESS, SET_IS_USER_EDITOR_OPEN,
    SET_LOAD_TENANT_USERS_IS_LOADING, SET_SAVE_TENANT_USERS_IS_LOADING, SET_USER_BEING_EDITED,
    UPDATE_TENANT_USER, UPLOAD_ADDRESSES_XLSX,
} from 'Containers/AdminConsole/consts'

export function loadTenantUsers(){
    return {
        type: LOAD_TENANT_USERS
    }
}
export function setLoadTenantUsersIsLoading(isLoading){
    return {
        type: SET_LOAD_TENANT_USERS_IS_LOADING,
        isLoading
    }
}
export function loadTenantUsersResult(result){
    return {
        type: LOAD_TENANT_USERS_RESULT,
        result
    }
}
export function updateTenantUser(email, user){
    return {
        type: UPDATE_TENANT_USER,
        email,
        user,
    }
}
export function deleteTenantUser(email){
    return {
        type: DELETE_TENANT_USER,
        email
    }
}
export function setSaveTenantUsersIsLoading(isLoading){
    return {
        type: SET_SAVE_TENANT_USERS_IS_LOADING,
        isLoading
    }
}
export function setIsUserEditorOpen(isOpen){
    return {
        type: SET_IS_USER_EDITOR_OPEN,
        isOpen
    }
}
export function setUserBeingEdited(emailAndUser){
    return {
        type: SET_USER_BEING_EDITED,
        emailAndUser
    }
}
export function uploadAddressesXlsx(addressesXlsx, progressUpdates, allowReduction,dispatch){
    return {
        type: UPLOAD_ADDRESSES_XLSX,
        addressesXlsx,
        progressUpdates,
        allowReduction,
        dispatch,
    }
}
export function publishChunk(chunk,statusCodeOverride){
    return {
        type: PUBLISH_CHUNK,
        chunk,
        statusCodeOverride,
    }
}
export function resetProgress(){
    return {
        type: RESET_PROGRESS

    }
}




