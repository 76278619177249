import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export function loadMiddlewares(dispatch){
    for (let saga of sagas){
        sagaMiddleware.run(saga, dispatch);
    }
}
const sagMid = [sagaMiddleware]
export default sagMid
