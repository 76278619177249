import {getCookie} from 'Helpers/cookies'
import {TENANT_ID, TENANTS, TOKEN} from 'Containers/Auth/consts'

export const getTenantUrlFromUrl = () => {
  const urlTenant = window.location.pathname.split('/')[1]
  return urlTenant ? `/${urlTenant}` : null
}
export const getTenantUrlFromCookie = () => getTenantFromCookie()?.url

export const getTenantFromUrl = () => {
  const urlTenant = window.location.pathname.split('/')[1]
  return TENANTS.find(tenant => tenant.url === `/${urlTenant}`)
}

export const getTenantFromCookie = () => {
  let tenantId = getCookie(TENANT_ID)
  return TENANTS.find(tenant => tenant.id === tenantId)
}
export const getTenant = () => getTenantFromCookie() || getTenantFromUrl()

export const getToken = () => {
  return getCookie(TOKEN)
}
