import { fromJS } from 'immutable';
import {
  LOGOUT,
  SET_IS_CONNECTED,
  SET_LOGGED_IN_USER, IS_CONNECTED, LOGGED_IN_USER, AUTHENTICATE_IS_LOADING, SET_AUTHENTICATE_IS_LOADING,
} from './consts';
import { IS_LOADING } from 'Redux/consts';

const initialState = fromJS({
  [IS_CONNECTED]: false,
  [IS_LOADING]: false,
  [LOGGED_IN_USER]: '',
  [AUTHENTICATE_IS_LOADING]: false,
});

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_CONNECTED:
      return state.set(IS_CONNECTED, action.isConnected)
    case SET_LOGGED_IN_USER:
      return state.set(LOGGED_IN_USER, action.loggedInUser)
    case SET_AUTHENTICATE_IS_LOADING:
      return state.set(AUTHENTICATE_IS_LOADING, action.isLoading)
    case LOGOUT:
    default:
      return state;
  }
}
