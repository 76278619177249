import {TICKETS_KEYWORD} from 'Containers/Tickets/consts'
import {
  CREATE_TICKET_FORM_VALUES,
  CREATE_TICKET_IS_LOADING,
  EDIT_TICKET_FORM_VALUES,
  LOAD_TICKETS_IS_LOADING,
  LOAD_SINGLE_TICKET_IS_LOADING,
  TICKETS_TABLE, RETRIEVE_TICKET_IS_LOADING,
  SINGLE_TICKET
} from 'Containers/Tickets/consts'

import {TICKETS} from 'Redux/consts'
import {createSelector} from 'reselect'


export const getEditTicketFormValues = createSelector(
  state => state.getIn([TICKETS,EDIT_TICKET_FORM_VALUES]),
  formValues => formValues?.toJS()
)

export const getCreateTicketFormValues = createSelector(
  state => state.getIn([TICKETS,CREATE_TICKET_FORM_VALUES]),
  formValues => formValues?.toJS()
)

export const getCreateTicketIsLoading = state => state.getIn([TICKETS,CREATE_TICKET_IS_LOADING])

export const getTicketsTable = createSelector(
  state => state.getIn([TICKETS,TICKETS_TABLE]),
  ticketsTable => ticketsTable?.toJS(),
)
export const getLoadTicketsIsLoading = state => state.getIn([TICKETS,LOAD_TICKETS_IS_LOADING])

export const getSingleTicket = state => state.getIn([TICKETS,SINGLE_TICKET])?.toJS()

export const getLoadSingleTicketIsLoading = state => state.getIn([TICKETS,LOAD_SINGLE_TICKET_IS_LOADING])
export const getRetrieveTicketIsLoading = state => state.getIn([TICKETS,RETRIEVE_TICKET_IS_LOADING])

export const getTicketsKeyword = createSelector(
  state => state.getIn([TICKETS,  TICKETS_KEYWORD]),
  keyword => keyword,
)
