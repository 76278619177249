import local from './config.local';

const commonConfig = {
    googleMapsKey: "",
}
const configMap = {
    local,
};

const getConfig = () => ({
    ...(configMap[process.env.REACT_APP_ENV] || local()),
    ...commonConfig
})
export default getConfig
