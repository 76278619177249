import {fromJS,merge} from "immutable";
import {
    CREATE_TICKET_FORM_VALUES,
    CREATE_TICKET_IS_LOADING,
    EDIT_TICKET_FORM_VALUES,
    HAS_MORE,
    LOAD_TICKETS_IS_LOADING,
    LOAD_TICKETS_RESULT,
    LOAD_SINGLE_TICKET_IS_LOADING,
    LOAD_SINGLE_TICKET_RESULT,
    TABLE_DATA,
    TICKETS_TABLE, RETRIEVE_TICKET_IS_LOADING, RETRIEVE_TICKET_WS7_RESULT,
    SET_CREATE_TICKET_FORM_VALUES,
    SET_CREATE_TICKET_IS_LOADING,
    SET_EDIT_TICKET_FORM_VALUES,
    SET_LOAD_TICKETS_IS_LOADING,
    SET_LOAD_SINGLE_TICKET_IS_LOADING,
    SET_RETRIEVE_TICKET_IS_LOADING,
    SINGLE_TICKET, TICKET_RETRIEVALS, SET_TICKETS_KEYWORD, TICKETS_KEYWORD
} from './consts'

const emptyTicketsTable = {
    [HAS_MORE]: true, // make default for 1st time loading
    [TABLE_DATA]: {},
}
const emptySingleTicket = {

}
const initialState = fromJS({
    [EDIT_TICKET_FORM_VALUES]: null,
    [CREATE_TICKET_FORM_VALUES]: null,
    [CREATE_TICKET_IS_LOADING]: false,
    [TICKETS_TABLE]: emptyTicketsTable,
    [LOAD_TICKETS_IS_LOADING]: false,
    [SINGLE_TICKET]: emptySingleTicket,
    [LOAD_SINGLE_TICKET_IS_LOADING]: false,
    [RETRIEVE_TICKET_IS_LOADING]: false,
    [TICKETS_KEYWORD]: '',
});

export default function TicketsReducer(state = initialState, action){
    switch (action.type) {
        case SET_EDIT_TICKET_FORM_VALUES:
            return state.set(EDIT_TICKET_FORM_VALUES, fromJS(action.formValues))
        case SET_CREATE_TICKET_FORM_VALUES:
            return state.set(CREATE_TICKET_FORM_VALUES, fromJS(action.formValues))
        case SET_CREATE_TICKET_IS_LOADING:
            return state.set(CREATE_TICKET_IS_LOADING,  action.isLoading)
        case LOAD_TICKETS_RESULT:
            return state.updateIn([TICKETS_TABLE, TABLE_DATA],
                tickets => merge(tickets, fromJS(action.result.tickets)))
              .setIn([TICKETS_TABLE, HAS_MORE],action.result.hasMore)
        case SET_LOAD_TICKETS_IS_LOADING:
            return state.set(LOAD_TICKETS_IS_LOADING, action.isLoading)
        case LOAD_SINGLE_TICKET_RESULT:
            return state.set(SINGLE_TICKET, fromJS(action.result))
        case SET_LOAD_SINGLE_TICKET_IS_LOADING:
            return state.set(LOAD_SINGLE_TICKET_IS_LOADING, action.isLoading)
        case SET_RETRIEVE_TICKET_IS_LOADING:
            return state.set(RETRIEVE_TICKET_IS_LOADING, action.isLoading)
        case RETRIEVE_TICKET_WS7_RESULT:
            return state.updateIn([SINGLE_TICKET, TICKET_RETRIEVALS],
              retrievals => retrievals.unshift(fromJS(action.result.response)))
        case SET_TICKETS_KEYWORD:
            return state.set(TICKETS_KEYWORD, action.keyword).set(TICKETS_TABLE,fromJS(emptyTicketsTable))
        default:
            return state
    }
}
