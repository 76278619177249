import {createSelector} from 'reselect'
import {AUTH} from 'Redux/consts'
import {
  AUTHENTICATE_IS_LOADING,
  IS_CONNECTED, LOGGED_IN_USER,
} from './consts'

const _getIsConnected = (state) => state.getIn([AUTH, IS_CONNECTED])
const _getLoggedInUser = (state) => state.getIn([AUTH, LOGGED_IN_USER])

export const getIsConnected = createSelector(_getIsConnected, (isConnected) => isConnected)
export const getLoggedInUser = createSelector(_getLoggedInUser, (loggedInUser) => loggedInUser)
export const getAuthenticateIsLoading = (state) => state.getIn([AUTH, AUTHENTICATE_IS_LOADING])

