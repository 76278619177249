import NewIcon from '@mui/icons-material/AddBox'
import Button from '@mui/material/Button'
import BasicModal from 'Components/Generic/BasicModal'
import Skeleton from 'Components/Skeleton'
import TenantUsersTable from 'Containers/AdminConsole/TenantUsersTable/TenantUsersTable'
import UserEditor from 'Containers/AdminConsole/UserEditor/UserEditor'
import {useEffect} from 'react'
import * as React from 'react'
import styles from './AdminConsole.module.scss'


const AdminConsole = ({loadTenantUsers, isUserEditorOpen, setIsUserEditorOpen, setUserBeingEdited}) => {
  useEffect(() => {
    loadTenantUsers()
  }, [loadTenantUsers])

  const newUser = () => {
    setUserBeingEdited(null)
    setIsUserEditorOpen(true)
  }


  return <Skeleton className={styles.container}>
    <h2>ניהול משתמשים</h2>
    <div className={styles.topButtons}>
      <Button variant={"contained"}
              color={"primary"}
              startIcon={<NewIcon/>} onClick={newUser}>משתמש חדש</Button>
    </div>
    <TenantUsersTable/>
    <BasicModal
      isMirror={false}
      className={styles.modal}
      isOpen={isUserEditorOpen}
      setIsOpen={setIsUserEditorOpen}
      title={'פרטי משתמש'}
      content={<UserEditor/>}
    />
  </Skeleton>
}
export default AdminConsole
