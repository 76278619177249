import TicketRetrievals from 'Components/Tickets/TicketsRetrievals/TicketRetreivals'
import {connect} from 'react-redux';

const mapDispatchToProps = () => {
  return {
  }
}

const mapStateToProps = () => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketRetrievals)
