import {Paper} from '@mui/material'
import classes from 'classnames'
import {formatBezeqDate} from 'Components/Tickets/ticketsUtils'
import styles from './CreateCheckLine.module.scss'
import * as React from 'react'

export default function CreateCheckLineRequestInfo({checkLineRequestInfo}) {
  const {messageDesc,lineCode,bezeqRequestId, box, tag, slaTo, faultYesNo} = checkLineRequestInfo

  return <Paper className={styles.resultsContainer} elevation={3}>
    <div className={classes({[styles.good]:checkLineRequestInfo?.messageCode===1,[styles.bad]:checkLineRequestInfo?.messageCode>1})}>
      {messageDesc}
    </div>
    {lineCode && <div>
      <div>קוד קו</div>
      <div>{lineCode}</div>
    </div>}
    {bezeqRequestId && <div>
      <div>מס׳ בקשה בבזק</div>
      <div>{bezeqRequestId}</div>
    </div>}
    {box && <div>
      <div>תיבה</div>
      <div>{box}</div>
    </div>}
    {tag && <div>
      <div>תג</div>
      <div>{tag}</div>
    </div>}
    {faultYesNo && <div>
        <div>קיימת תקלת שברון?</div>
        <div>{Number(faultYesNo) === 1 ? 'כן' : 'לא'}</div>
      </div>}
    {slaTo && <div>
      <div>תאריך לתיקון שיברון</div>
      <div>{formatBezeqDate(slaTo)}</div>
    </div>}
  </Paper>
}
