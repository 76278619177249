import {Paper} from '@mui/material'
import classes from 'classnames'
import {CHECK_LINE_ACTION_CODE} from 'Containers/AppGeneral/consts'
import styles from './CheckLineResultInfo.module.scss'
import * as React from 'react'

export default function CheckLineResultInfo({checkLineResultInfo}) {
  const response = checkLineResultInfo?.response || {}
  const {
    lineCode,
    gponInfo: {actionCode, gponParams},
    bezeqRequestId,
    box,
    tag,
    actualBitRate,
    maxAttainable,
    lineAttenuation,
    noise,
    vectoringFlag,
    voltageRgAc,
    voltageTgAc,
    voltageTrAc,
    voltageRgDc,
    voltageTgDc,
    voltageTrDc,
    resistanceRgDc,
    resistanceTgDc,
    capacitanceRgDc,
    capacitanceTgDc,
    resistanceTrDc,
  } = response

  return <Paper className={styles.resultsContainer} elevation={3}>
    <div className={classes({[styles.good]: Number(response.messageCode) === 1, [styles.bad]: Number(response.messageCode) > 1})}>
      {response.messageDesc}
    </div>
    {lineCode && <div>
      <div>קוד קו</div>
      <div>{lineCode}</div>
    </div>}
    {actionCode && <div>
      <div>קוד פעולה</div>
      <div>{actionCode}</div>
    </div>}
    {actionCode && <div>
      <div>תיאור פעולה</div>
      <div>{CHECK_LINE_ACTION_CODE[actionCode]}</div>
    </div>}
    {bezeqRequestId && <div>
      <div>מס׳ בקשה בבזק</div>
      <div>{bezeqRequestId}</div>
    </div>}
    {box && <div>
      <div>box</div>
      <div>{box}</div>
    </div>}
    {tag && <div>
      <div>tag</div>
      <div>{tag}</div>
    </div>}
    {actualBitRate && <div>
      <div>actualBitRate</div>
      <div>{actualBitRate}</div>
    </div>}
    {maxAttainable && <div>
      <div>maxAttainable</div>
      <div>{maxAttainable}</div>
    </div>}
    {lineAttenuation && <div>
      <div>lineAttenuation</div>
      <div>{lineAttenuation}</div>
    </div>}
    {noise && <div>
      <div>noise</div>
      <div>{noise}</div>
    </div>}
    {vectoringFlag && <div>
      <div>vectoringFlag</div>
      <div>{vectoringFlag}</div>
    </div>}
    {voltageRgAc && <div>
      <div>voltageRgAc</div>
      <div>{voltageRgAc}</div>
    </div>}
    {voltageTgAc && <div>
      <div>voltageTgAc</div>
      <div>{voltageTgAc}</div>
    </div>}
    {voltageTrAc && <div>
      <div>voltageTrAc</div>
      <div>{voltageTrAc}</div>
    </div>}
    {voltageRgDc && <div>
      <div>voltageRgDc</div>
      <div>{voltageRgDc}</div>
    </div>}
    {voltageTgDc && <div>
      <div>voltageTgDc</div>
      <div>{voltageTgDc}</div>
    </div>}
    {voltageTrDc && <div>
      <div>voltageTrDc</div>
      <div>{voltageTrDc}</div>
    </div>}
    {resistanceRgDc && <div>
      <div>resistanceRgDc</div>
      <div>{resistanceRgDc}</div>
    </div>}
    {resistanceTgDc && <div>
      <div>resistanceTgDc</div>
      <div>{resistanceTgDc}</div>
    </div>}
    {capacitanceRgDc && <div>
      <div>capacitanceRgDc</div>
      <div>{capacitanceRgDc}</div>
    </div>}
    {capacitanceTgDc && <div>
      <div>capacitanceTgDc</div>
      <div>{capacitanceTgDc}</div>
    </div>}
    {resistanceTrDc && <div>
      <div>resistanceTrDc</div>
      <div>{resistanceTrDc}</div>
    </div>}
    {gponParams && gponParams.map(({paramName, paramValue},index) => <div key={index}><div>GPON:{paramName}</div><div>{paramValue}</div></div>)}
  </Paper>
}
