import {toast} from "react-toastify";
import Toastr from "Components/Toastr";
import React from "react";
import styles from 'Components/Toastr/styles.module.scss';

const defaultOptions = {
    className: styles.toastrCont
}

class Notifier {
    success(msg, options) {
        this.success.bind(this)
        toast.success(<Toastr message={msg}/>, {...options, ...defaultOptions})
    }

    error(msg, options) {
        this.error.bind(this)
        toast.error(<Toastr message={msg}/>, {...options, ...defaultOptions})
    }

    info(msg, options) {
        this.info.bind(this)
        toast.info(<Toastr message={msg}/>, {...options, ...defaultOptions})
    }
}

export const toastr = new Notifier();
