import {takeEvery} from 'redux-saga/effects'
import {ON_FAILURE, ON_SUCCESS, ON_WARNING} from "./consts";
import {toastr} from "../../Helpers/toastr";
import { isString } from 'lodash';

export function onSuccess({message = "Action completed successfully"}) {
    toastr.success(message)
}

export function onWarning({message = "Action partially completed"}) {
    toastr.info(message)
}

export function onFailure({message = "Error while completing action", e}) {
    if (isString(message)){
        toastr.error(message)
    }
    if(e){
        console.error(e);
    }
}


export default function* defaultSaga() {
    yield takeEvery(ON_SUCCESS, onSuccess);
    yield takeEvery(ON_WARNING, onWarning);
    yield takeEvery(ON_FAILURE, onFailure);
}
