import {IS_USER_EDITOR_OPEN, LOAD_TENANT_USERS_IS_LOADING, SAVE_TENANT_USERS_IS_LOADING, SERVER_PROGRESS, TENANT_USERS, USER_BEING_EDITED} from 'Containers/AdminConsole/consts'
import {ADMIN_CONSOLE} from 'Redux/consts'
import {createSelector} from 'reselect'

export const getTenantUsers = createSelector(
  state => state.getIn([ADMIN_CONSOLE,TENANT_USERS]),
  tenantUsers => tenantUsers?.toJS(),
)
export const getLoadTenantUsersIsLoading = state => state.getIn([ADMIN_CONSOLE,LOAD_TENANT_USERS_IS_LOADING])

export const getIsUserEditorOpen = state => state.getIn([ADMIN_CONSOLE, IS_USER_EDITOR_OPEN])

export const getUserBeingEdited = state => state.getIn([ADMIN_CONSOLE, USER_BEING_EDITED])

export const getSaveTenantUsersIsLoading = state => state.getIn([ADMIN_CONSOLE, SAVE_TENANT_USERS_IS_LOADING])

export const getServerProgress = createSelector(
  state => state.getIn([ADMIN_CONSOLE, SERVER_PROGRESS]),
  progress => progress.toJS(),
  )
