import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import {Button, Card, TextField} from '@mui/material'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import useEmailValidator from 'Hooks/useEmailValidator'
import useRequiredValidator from 'Hooks/useRequiredValidator'
import React, {useEffect, useState} from "react"
import {getTenant} from 'Helpers/tenants'
import {AIRGAP_AUTH} from 'Redux/consts'
import styles from './AuthBox.module.scss'

const AuthBox = ({authenticate, disconnect, isConnected, loggedInUser, authorizationMethod, authenticateIsLoading}) => {
  const [handleEmailChange, emailErrors, email] = useEmailValidator()
  const [handlePasswordChange, passwordErrors, password] = useRequiredValidator()
  const [isSubmitOk, setIsSubmitOk] = useState(false)
  const validationObject = {}

  function monitor(validatorStateKeyVal) { // runs inside render cycle
    const key = Object.keys(validatorStateKeyVal)[0]
    const value = validatorStateKeyVal[key]
    validationObject[key] = !!value
    return !!value
  }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    setIsSubmitOk(!atLeastOneError)
  })
  return (
    <div className={styles.container}>
      {isConnected ?
        <div>
          <h3>שלום לך,&nbsp;
            {loggedInUser?.displayName}</h3>
          <h5>{getTenant()?.displayName}</h5>
          <Button variant="contained"
                  color="primary"
                  startIcon={<LogoutIcon/>}
                  onClick={disconnect}>התנתקות</Button>
        </div>
        : <>
          {authorizationMethod === AIRGAP_AUTH &&
            <Card variant="outlined" className={styles.airgap}>
              <form className={styles.form} onSubmit={(e) => {
                e.preventDefault()
                authenticate(email, password)
              }}>
                <TextField
                  required
                  name="email"
                  label="Email"
                  variant="standard"
                  onChange={handleEmailChange}
                  error={monitor({emailErrors})}
                  helperText={emailErrors}
                  autoComplete="username"
                />
                <TextField
                  required
                  name="password"
                  label="Password"
                  type="password"
                  variant="standard"
                  onChange={handlePasswordChange}
                  error={monitor({passwordErrors})}
                  helperText={passwordErrors}
                  autoComplete="current-password"
                />
                <button hidden={true}>submit</button>
              </form>
            </Card>
          }
          <SpinnerButton variant="contained"
                         color="primary"
                         isLoading={authenticateIsLoading}
                         startIcon={<LoginIcon/>}
                         disabled={authorizationMethod === AIRGAP_AUTH && !isSubmitOk}
                         onClick={() => authenticate(email, password)}>התחברות</SpinnerButton>
        </>
      }
    </div>
  )
};

export default AuthBox;
