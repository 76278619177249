import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import CreateIcon from '@mui/icons-material/Add'
import {Option, Select} from '@mui/joy'
import {Paper} from '@mui/material'
import TextField from '@mui/material/TextField'
import classes from 'classnames'
import Mirror from 'Components/Generic/Mirror'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import Skeleton from 'Components/Skeleton'
import {CHECK_LINE_ACTION_CODE, LINE_BROKEN_CODE} from 'Containers/AppGeneral/consts'
import {objectToArray} from 'Helpers/arrays'
import useNumericDigitsValidator from 'Hooks/useNumericDigitsValidator'
import useRequiredValidator from 'Hooks/useRequiredValidator'
import {useEffect, useMemo, useRef, useState} from 'react'
import styles from './CreateCheckLine.module.scss'
import CreateCheckLineRequestInfo from './CreateCheckLineRequestInfo.jsx'

const CreateCheckLine = ({checkLineRequestIsLoading, createCheckLineRequestWs4, checkLineRequestInfo, orderId}) => {

  const formRef = useRef()
  const [isCheckLineSubmitOk, setIsCheckLineSubmitOk] = useState(false)
  const [handleLineCodeChange, lineCodeErrors] = useNumericDigitsValidator({minLength:1, required:true})
  const [handleActionCodeChange,,actionCodeValue] = useRequiredValidator()

  const getFormValues = () => {
    var data = new FormData(formRef.current)
    let formObject = Object.fromEntries(data.entries())
    Object.keys(formObject).forEach(formKey => {
      if (formObject[formKey] === 'true@@@') {
        formObject[formKey] = true
      }
    })
    return formObject
  }
  const isBrokenLineCheck = useMemo(() => Number(actionCodeValue) === LINE_BROKEN_CODE,[actionCodeValue])
  const checkLineFormSubmit = (e) => {
    e.preventDefault()
    createCheckLineRequestWs4(getFormValues())
  }

  const isFormDisabled = false

  const validationObject = {}

  function monitor(formVar) { // runs inside render cycle
    const key = Object.keys(formVar)[0]
    const value = formVar[key]
    validationObject[key] = !!value
    return !!value
  }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    const formValues = getFormValues()
    const areValuesMissing = !orderId || !formValues.actionCode
    setIsCheckLineSubmitOk(!atLeastOneError && !areValuesMissing)
  })

  return <Skeleton className={styles.container}>
      <div className={styles.headerContainer}>
        <h3>בקשה לבדיקת קו</h3>
        {orderId && <span>(להזמנה מס׳ {orderId})</span>}
      </div>
      <div>
        <div className={classes(styles.formEditorContainer)}>
          <Paper elevation={3}>
            <Mirror>
              <form ref={formRef} onSubmit={checkLineFormSubmit}>
                <TextField name="orderId"
                           value={orderId}
                           inputProps={{readOnly: true}}
                />
                {/*<TextField label="קוד קו"*/}
                {/*           type="string"*/}
                {/*           name="lineCode"*/}
                {/*           onChange={handleLineCodeChange}*/}
                {/*           error={monitor({lineCodeErrors})}*/}
                {/*           helperText={lineCodeErrors}*/}
                {/*           inputProps={{maxLength: 10}}*/}
                {/*           disabled={isFormDisabled}*/}
                {/*           required={true}*/}
                {/*/>*/}
                <Select placeholder="קוד פעולה"
                        name="actionCode"
                        disabled={isFormDisabled}
                        onChange={handleActionCodeChange}
                >
                  {objectToArray(CHECK_LINE_ACTION_CODE).map(item =>
                    (<Option key={item?.value} value={item?.value}>{item?.label}</Option>)
                  )}
                </Select>
                <SpinnerButton disabled={checkLineRequestIsLoading || !isCheckLineSubmitOk}
                                                      isLoading={checkLineRequestIsLoading}
                                                      type="submit"
                               startIcon={isBrokenLineCheck ? <BrokenImageIcon/> : <CreateIcon/>}
                >{isBrokenLineCheck ? 'בדוק שיברון' : 'יצירת בקשה'}</SpinnerButton>
              </form>
            </Mirror>
            {checkLineRequestInfo && <CreateCheckLineRequestInfo checkLineRequestInfo={checkLineRequestInfo} />}
          </Paper>
        </div>
      </div>
  </Skeleton>
}

export default CreateCheckLine
