import {
  CREATE_TICKET_WS8, LOAD_TICKETS, LOAD_TICKETS_RESULT, LOAD_SINGLE_TICKET, LOAD_SINGLE_TICKET_RESULT,
  RETRIEVE_TICKET_WS7, RETRIEVE_TICKET_WS7_RESULT, SET_CREATE_TICKET_FORM_VALUES,
  SET_CREATE_TICKET_IS_LOADING,
  SET_EDIT_TICKET_FORM_VALUES, SET_LOAD_TICKETS_IS_LOADING,
  SET_LOAD_SINGLE_TICKET_IS_LOADING,
  SET_RETRIEVE_TICKET_IS_LOADING, SET_TICKETS_KEYWORD,
} from 'Containers/Tickets/consts'


export function setEditTicketFormValues(formValues = {}) {
  return {
    type: SET_EDIT_TICKET_FORM_VALUES,
    formValues
  }
}

export function setCreateTicketFormValues(formValues = {}) {
  return {
    type: SET_CREATE_TICKET_FORM_VALUES,
    formValues
  }
}

export function setCreateTicketIsLoading(isLoading) {
  return {
    type: SET_CREATE_TICKET_IS_LOADING,
    isLoading
  }
}

export function createTicketWs8(redirect, formValues) {
  return {
    type: CREATE_TICKET_WS8,
    redirect,
    formValues,
  }
}

export function loadTickets(offset, limit, keyword, orderId) {
  console.log('action load tickets created', {offset, limit, keyword, orderId})
  return {
    type: LOAD_TICKETS,
    offset,
    limit,
    keyword,
    orderId
  }
}
export function loadTicketsResult(result){
    return {
        type: LOAD_TICKETS_RESULT,
        result
    }
}
export function setLoadTicketsIsLoading(isLoading){
    return {
        type: SET_LOAD_TICKETS_IS_LOADING,
        isLoading
    }
}
export function loadSingleTicket(ticketId){
    return {
        type: LOAD_SINGLE_TICKET,
        ticketId
    }
}
export function loadSingleTicketResult(result = {}){
    return {
        type: LOAD_SINGLE_TICKET_RESULT,
        result
    }
}
export function setLoadSingleTicketIsLoading(isLoading){
    return {
        type: SET_LOAD_SINGLE_TICKET_IS_LOADING,
        isLoading
    }
}
export function retrieveTicketWs7(ticketId){
    return {
        type: RETRIEVE_TICKET_WS7,
        ticketId
    }
}
export function retrieveTicketWs7Result(result){
    return {
        type: RETRIEVE_TICKET_WS7_RESULT,
        result
    }
}
export function setRetrieveTicketIsLoading(isLoading){
    return {
        type: SET_RETRIEVE_TICKET_IS_LOADING,
        isLoading
    }
}
export function setTicketsKeyword(keyword){
  return {
    type: SET_TICKETS_KEYWORD,
    keyword
  }
}
