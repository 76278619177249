import ResponseIcon from '@mui/icons-material/SignalCellularAlt'
import ReplayIcon from '@mui/icons-material/Replay'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import styles from 'Components/CheckLines/CheckLinesTable/index.module.scss'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import {CHECK_LINE_ACTION_CODE} from 'Containers/AppGeneral/consts'
import {TABLE_DATA} from 'Containers/CheckLines/consts'
import React from 'react'

const CheckLinesContainerHolder = React.forwardRef((props, ref) => {

  const {handleClick, checkLineOutcomeIsLoadingAndId} = props
  const tableData = props?.[TABLE_DATA]
  return <TableContainer className={styles.tableContainerHolder}  ref={ref}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={styles.notOnMobile}>מזהה</TableCell>
          <TableCell>קוד קו</TableCell>
          <TableCell className={styles.notOnMobile}>מזהה בזק</TableCell>
          <TableCell>פעולה</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(tableData).map(id => (
          <TableRow key={id}
                    hover
                    onClick={(event) => handleClick(event, id)}
          >
            <TableCell className={styles.notOnMobile}>{id}</TableCell>
            <TableCell>{tableData[id].lineCode}</TableCell>
            <TableCell className={styles.notOnMobile}>{tableData[id].bezeqRequestId}</TableCell>
            <TableCell>{CHECK_LINE_ACTION_CODE[tableData[id]?.actionCode]}</TableCell>
            <TableCell>
              <SpinnerButton
                color={tableData[id].response?'success':'primary'}
                variant={tableData[id].response?'contained':'outlined'}
                startIcon={tableData[id].response?<ResponseIcon/>:<ReplayIcon/>}
                isLoading={checkLineOutcomeIsLoadingAndId === Number(id)}
              >
                {tableData[id].response?'הצגת תשובה':'אחזור סטטוס'}
              </SpinnerButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
})

export default CheckLinesContainerHolder
