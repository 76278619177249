import NewIcon from '@mui/icons-material/AddCircleOutline'
import {debounce, TextField} from '@mui/material'
import Button from '@mui/material/Button'
import Mirror from 'Components/Generic/Mirror'
import Skeleton from 'Components/Skeleton'
import TicketsTable from 'Containers/Tickets/TicketsTable/TicketsTable'
import {toastr} from 'Helpers/toastr'
import * as React from 'react'
import styles from './Tickets.module.scss'

const Tickets = ({ticketsKeyword, setTicketsKeyword, orderId}) => {
  const _setTicketsKeyword = debounce(change => {
    setTicketsKeyword(change);
  }, 250);

  return <Skeleton className={styles.container}>
    <h2>רשימת תקלות </h2>
    {orderId && <h3>לפי הזמנה {orderId}</h3>}
    <div className={styles.topButtons}>
      <Button  variant={"contained"}
               color={"primary"}
               startIcon={<NewIcon/>} onClick={() => {toastr.info('יש לפתוח את התקלה מתוך הזמנה קיימת')}}>תקלה חדשה</Button>
      <Mirror>
        <TextField
          label="חיפוש"
          type="search"
          variant="standard"
          onChange={({target: {value}}) => _setTicketsKeyword(value)}
          defaultValue={ticketsKeyword}
        />
      </Mirror>
    </div>
    <TicketsTable orderId={orderId} />
  </Skeleton>
}
export default Tickets
