import TicketsTable from 'Components/Tickets/TicketsTable/TicketsTable'
import {getTicketsKeyword} from 'Containers/Tickets/selectors'
import {loadTickets} from 'Containers/Tickets/actions'
import {getLoadTicketsIsLoading, getTicketsTable} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  ticketsTable: getTicketsTable(state),
  loadTicketsIsLoading: getLoadTicketsIsLoading(state),
  ticketsKeyword: getTicketsKeyword(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadTickets: (offset, limit, keyword, orderId) => dispatch(loadTickets(offset, limit, keyword, orderId)),
})


export default connect(mapStateToProps, mapDispatchToProps)(TicketsTable)
