import CheckLinesTable from 'Components/CheckLines/CheckLinesTable/CheckLinesTable'
import {createCheckLineOutcomeWs5, loadCheckLines} from 'Containers/CheckLines/actions'
import {getLoadCheckLinesIsLoading, getCheckLinesTable, getCheckLineOutcomeIsLoadingAndId, getCheckLinesKeyword} from 'Containers/CheckLines/selectors'
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  checkLinesTable: getCheckLinesTable(state),
  loadCheckLinesIsLoading: getLoadCheckLinesIsLoading(state),
  checkLineOutcomeIsLoadingAndId: getCheckLineOutcomeIsLoadingAndId(state),
  checkLinesKeyword: getCheckLinesKeyword(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadCheckLines: (offset, limit, keyword) => dispatch(loadCheckLines(offset, limit, keyword)),
  createCheckLineOutcomeWs5: request => dispatch(createCheckLineOutcomeWs5(request)),
})


export default connect(mapStateToProps, mapDispatchToProps)(CheckLinesTable)
