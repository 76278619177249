// store branches
export const AUTH = 'Auth'
export const APP_GENERAL = 'AppGeneral'
export const ORDERS = 'Orders'
export const CHECK_LINES = 'CheckLines'
export const TICKETS = 'Tickets'
export const ADMIN_CONSOLE = 'AdminConsole'

// generic
export const IS_LOADING = 'isLoading'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const CITIES = 'cities'
export const STREETS = 'streets'

// auth
export const FIREBASE_AUTH = 'firebaseAuth'
export const AIRGAP_AUTH = 'airgapAuth'
