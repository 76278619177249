import apiServer from 'Api/Backend'
import {loadEssentialStructures, setIsMainLoading} from 'Containers/AppGeneral/actions'
import {initializeApp} from 'firebase/app'
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import {getCookie, setCookie} from 'Helpers/cookies'
import {getTenantFromUrl} from 'Helpers/tenants'
import {toastr} from 'Helpers/toastr'
import {sleep} from 'Helpers/utils'
import {redirect} from 'Hooks/useRedirect'
import {eventChannel} from 'redux-saga'
import {call, put, take, takeEvery} from 'redux-saga/effects';
import {AIRGAP_AUTH, FIREBASE_AUTH} from 'Redux/consts'
import getConfig from 'Config/config'
import {ON_LOAD} from "../AppGeneral/consts";
import {disconnect, setAuthenticateIsLoading, setIsConnected, setLoggedInUser} from "./actions";
import {AUTHENTICATE, DISCONNECT, RESTORE_AIRGAP_SESSION, TENANT_ID, TENANTS, TOKEN, WATCH_FOR_FIREBASE_AUTH} from "./consts";

let app = null
let authChannel = null

function getAuthChannel() {
  if (!app) {
    app = initializeApp(getConfig().firebaseConfig)
  }
  const auth = getAuth(app);

  if (!authChannel) {
    authChannel = eventChannel(emit => {
      return auth.onAuthStateChanged(user => emit({user}));
    });
  }
  return authChannel;
}

async function getTokenWithClaims() {
  const auth = getAuth(app);
  return await auth?.currentUser?.getIdTokenResult()
}

function* watchForFirebaseAuthSaga() {
  try {
    const channel = yield call(getAuthChannel)
    while (true) {
      const result = yield take(channel)
      const {user} = result
      if (user) {
        setCookie(TOKEN, user?.accessToken, 1)
        const {claims} = yield call(getTokenWithClaims)
        const loggedInUser = {
          displayName: user?.displayName,
          geobez: claims?.geobez,
        }
        console.log('gizim ', {claims})
        if (user?.tenantId && claims?.geobez) {
          setCookie(TENANT_ID, user.tenantId)
          yield put(setLoggedInUser(loggedInUser))
          yield put(setIsConnected(true))
          yield put(loadEssentialStructures())
        } else {
          yield put(disconnect())
          toastr.error('המשתמש אינו מורשה')
        }
        // start pulse immediately to detect contradiction between tenant
        // in token and tenant in url (can happen with admins who toy with urls)
        // startPulse()gizim
      } else {
        yield put(setLoggedInUser(null))
        yield put(setIsConnected(false))
      }
      yield put(setIsMainLoading(false))
    }
  } finally {
    console.log('watchForFirebaseAuth ended')
  }
}


//function* startPulse() {
//   console.log('gizim ', 'pulse started')
//   let userIsConnected = true
//   // start pulse immediately to detect contradiction between tenant
//   // in token and tenant in url (can happen with admins who toy with urls)
//   while (userIsConnected) {
//     userIsConnected = await getPulse()
//     if (!userIsConnected) {
//       const tenantId = appStateSelectors.getSelectedTenant().getState()
//       appStateReducers.setLoggedInUser(null)
//       appStateReducers.setIsConnected(false)
//       await disconnect()
//       const tenantNiceURL = TENANTS.find(tenant => tenant.id === tenantId)?.url
//       console.log('gizim ', 'no pulse. redirecting...', tenantNiceURL)
//       redirectTo({url: (tenantNiceURL ? tenantNiceURL : '')})
//     }
//     await sleep(WAIT_MS_BETWEEN_PULSES)
//   }
// }
function* authenticateSaga({email, password}) {
  switch (getConfig().authorizationMethod) {
    case FIREBASE_AUTH:
      yield authenticateFirebaseSaga()
      break;
    case AIRGAP_AUTH:
      yield authenticateAirGapSaga(email, password)
      break;
    default:
      console.error('authentication method not defined')
  }
}

function* authenticateAirGapSaga(email, password) {
  yield put(setAuthenticateIsLoading(true))
  try {
    const tenant = getTenantFromUrl()?.id
    const result = yield call(apiServer.post, 'login', {email, password, tenant})
    yield call(sleep, 1000)
    const user = result?.user
    if (user) {
      const {claims} = user
      const loggedInUser = {
        displayName: user?.displayName,
        geobez: claims?.geobez,
      }
      setCookie(TOKEN, user?.accessToken, 1)
      if (user?.tenantId && claims?.geobez) {
        setCookie(TENANT_ID, user.tenantId)
        yield put(setLoggedInUser(loggedInUser))
        yield put(setIsConnected(true))
        yield put(loadEssentialStructures())
      } else {
        yield put(disconnect())
        toastr.error('המשתמש אינו מורשה')
      }
    } else {
      toastr.error('המשתמש אינו מורשה')
    }
  } catch (e) {
    console.error(e)
    if (e?.response?.status === 401) {
      toastr.error(`המשתמש אינו מורשה ${e?.response?.data}`)
    } else if (e?.response?.status === 400) {
      toastr.error(`פרמטרים שגויים ${e?.response?.data}`)
    } else {
      toastr.error('שגיאת תקשורת')
    }
  }
  yield put(setAuthenticateIsLoading(false))
}

function* authenticateFirebaseSaga() {
  if (!app) {
    app = initializeApp(getConfig().firebaseConfig)
  }
  const pathnameWithoutTrailingSlashes = window.location.pathname.replace(/\/+$/, '');
  const tenantFromUrl = TENANTS.find(tenant => tenant.url === pathnameWithoutTrailingSlashes)
  if (!tenantFromUrl) {
    throw `url ${pathnameWithoutTrailingSlashes} is not related with any known tenants`
  }
  const auth = getAuth(app)
  auth.tenantId = String(tenantFromUrl.id)
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account'
  });
  auth.useDeviceLanguage()
  let userCred
  try {
    userCred = yield call(signInWithPopup, auth, provider)
    console.log('firebase says MFA not needed', userCred.user)
  } catch (error) {
    if ((error)?.code === 'auth/multi-factor-auth-required') {
      console.log('firebase says MFA REQUIRED')
      // userCred = await mfa(userCred, auth, error)
    } else {
      throw error
    }
  }
}

async function signOutServer() {
  if (getConfig().authorizationMethod === FIREBASE_AUTH) {
    if (!app) {
      app = initializeApp(getConfig().firebaseConfig)
    }
    const auth = getAuth(app)
    await auth.signOut()
  } else if (getConfig().authorizationMethod === AIRGAP_AUTH) {
    await apiServer.post('logout')
  }
  setCookie(TENANT_ID, '')
  setCookie(TOKEN, '')
}

function* disconnectSaga() {
  yield call(signOutServer)
  yield put(setLoggedInUser(null))
  yield put(setIsConnected(false))
  redirect('/')
}

function* restoreAirgapSessionSaga() {
  let tenantId = getCookie(TENANT_ID)
  const token = getCookie(TOKEN)
  if (tenantId && token) {
    try {
      const authResult = yield call(apiServer.get, 'userInfo')
      if (authResult?.email) {
        const {name, email, tenant, geobez} = authResult
        setCookie(TENANT_ID, tenant)
        yield put(setLoggedInUser({displayName: name, email, geobez}))
        yield put(setIsConnected(true))
        yield put(loadEssentialStructures())
      }
    } catch (e) {
      yield put(disconnect())
    }
  }
  yield put(setIsMainLoading(false))
}


function* onLoadSaga() {
  if (getConfig().authorizationMethod === FIREBASE_AUTH) {
    try {
      yield watchForFirebaseAuthSaga()
    } catch (e) {
      console.error(e)
    }
  } else if (getConfig().authorizationMethod === AIRGAP_AUTH) {
    yield restoreAirgapSessionSaga()
  } else {
    yield put(setIsMainLoading(false))
  }
}

export default function* defaultSaga() {
  yield takeEvery(ON_LOAD, onLoadSaga)
  yield takeEvery(WATCH_FOR_FIREBASE_AUTH, watchForFirebaseAuthSaga)
  yield takeEvery(AUTHENTICATE, authenticateSaga)
  yield takeEvery(DISCONNECT, disconnectSaga)
  yield takeEvery(RESTORE_AIRGAP_SESSION, restoreAirgapSessionSaga)
}
