import SysTables from 'Components/AdminConsole/SysTables/SysTables'
import {resetProgress, uploadAddressesXlsx} from 'Containers/AdminConsole/actions'
import {getServerProgress} from 'Containers/AdminConsole/selectors'
import {connect} from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    uploadAddressesXlsx: (addressesXlsx,progressUpdates,allowReduction) => dispatch(uploadAddressesXlsx(addressesXlsx,progressUpdates,allowReduction,dispatch)),
    resetProgress: () => dispatch(resetProgress()),
  }
};
const mapStateToProps = (state) => {
  return {
    serverProgress: getServerProgress(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SysTables)
