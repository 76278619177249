import LocateCustomerInfra from 'Components/Orders/LocateCustomerInfra/LocateCustomerInfra'
import {setIsScrollWanted} from 'Containers/AppGeneral/actions'
import {getCities, getIsScrollWanted, getStreets} from 'Containers/AppGeneral/selectors'
import {locateCustomerWs1, setLocateCustomerInfraFormValues} from 'Containers/Orders/actions'
import {getCustomerInfraInfo, getLocateCustomerInfraFormValues} from 'Containers/Orders/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    locateCustomer: (formData) => dispatch(locateCustomerWs1(formData)),
    setLocateCustomerInfraFormValues: (formData) => dispatch(setLocateCustomerInfraFormValues(formData)),
    setIsScrollWanted: isScrollWanted => dispatch(setIsScrollWanted(isScrollWanted)),
  }
};
const mapStateToProps = (state,) => {
  return {
    cities: getCities(state),
    streets: getStreets(state),
    locateCustomerInfraFormValues: getLocateCustomerInfraFormValues(state),
    customerInfraInfo: getCustomerInfraInfo(state),
    isScrollWanted: getIsScrollWanted(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocateCustomerInfra)
