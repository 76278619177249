import {Backdrop, CircularProgress} from '@mui/material'
import {getIsMainLoading} from 'Containers/AppGeneral/selectors'
import {connect} from "react-redux";
import {useEffect} from "react";
import {onLoad} from "./actions";

const OnLoad = ({onLoad, isMainLoading}) => {
  useEffect(() => {
    onLoad();
  }, [onLoad])
  return isMainLoading && <Backdrop
    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
    open={isMainLoading}
  >
    <CircularProgress color="inherit"/>
  </Backdrop>
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: () => dispatch(onLoad()),
  };
};
const mapStateToProps = (state) => {
  return {
    isMainLoading: getIsMainLoading(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnLoad);
