import TenantUsersTable from 'Components/AdminConsole/TenantUsersTable/TenantUsersTable'
import {setIsUserEditorOpen, setUserBeingEdited} from 'Containers/AdminConsole/actions'
import {getLoadTenantUsersIsLoading, getTenantUsers} from 'Containers/AdminConsole/selectors'
import {getLoggedInUser} from 'Containers/Auth/selectors'
import {connect} from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    setUserBeingEdited: (emailAndUser) => dispatch(setUserBeingEdited(emailAndUser)),
    setIsUserEditorOpen: isOpen => dispatch(setIsUserEditorOpen(isOpen)),
  }
};
const mapStateToProps = (state,) => {
  return {
    tenantUsers: getTenantUsers(state),
    loadTenantUsersIsLoading: getLoadTenantUsersIsLoading(state),
    loggedInUser: getLoggedInUser(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantUsersTable)
