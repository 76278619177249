export const CREATE_CHECK_LINE_REQUEST_WS4 = 'CREATE_CHECK_LINE_REQUEST_WS4'
export const CREATE_CHECK_LINE_REQUEST_WS4_RESULT = 'CREATE_CHECK_LINE_REQUEST_WS4_RESULT'
export const CHECK_LINE_REQUEST_INFO = 'checkLineRequestInfo'
export const CREATE_CHECK_LINE_REQUEST_IS_LOADING = 'createCheckLineRequestIsLoading'
export const SET_CREATE_CHECK_LINE_REQUEST_IS_LOADING = 'SET_CREATE_CHECK_LINE_REQUEST_IS_LOADING'

export const LOAD_CHECK_LINES = 'LOAD_CHECK_LINES'
export const LOAD_CHECK_LINES_RESULT = 'LOAD_CHECK_LINES_RESULT'
export const CHECK_LINES_TABLE = 'checkLinesTable'
export const TABLE_DATA = 'tableData'
export const HAS_MORE = 'hasMore'
export const LOAD_CHECK_LINES_IS_LOADING = 'loadCheckLinesIsLoading'
export const SET_LOAD_CHECK_LINES_IS_LOADING = 'SET_LOAD_CHECK_LINES_IS_LOADING'

export const CREATE_CHECK_LINE_OUTCOME_WS5 = 'CREATE_CHECK_LINE_OUTCOME_WS5'
export const CREATE_CHECK_LINE_OUTCOME_WS5_RESULT = 'CREATE_CHECK_LINE_OUTCOME_WS5_RESULT'
export const CHECK_LINE_OUTCOME_INFO = 'checkLineOutcomeInfo'
export const CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID = 'createCheckLineOutcomeIsLoadingAndId'
export const SET_CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID = 'SET_CREATE_CHECK_LINE_OUTCOME_IS_LOADING_AND_ID'

export const CHECK_LINES_KEYWORD = 'checkLinesKeyword'
export const SET_CHECK_LINES_KEYWORD = 'SET_CHECK_LINES_KEYWORD'
