import CreateIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import UpdateIcon from '@mui/icons-material/Update'
import SupportIcon from '@mui/icons-material/Build'
import ActivateIcon from '@mui/icons-material/PlayArrow'
import FinishSaleIcon from '@mui/icons-material/DoneAll'
import SignatureIcon from '@mui/icons-material/BorderColor'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import {Option, Select} from '@mui/joy'
import {Autocomplete, Checkbox, FormControlLabel, Paper} from '@mui/material'
import TextField from '@mui/material/TextField'
import classes from 'classnames'
import BasicModal from 'Components/Generic/BasicModal'
import Mirror from 'Components/Generic/Mirror'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import RetrievedTagInfo from 'Components/Orders/RetrievedTagInfo/RetrievedTagInfo'
import {emptyCity, emptyStreet, GPON_BUILDING_TYPE} from 'Containers/AppGeneral/consts'
import OrderRetrievals from 'Containers/Orders/OrderRetrievals/OrderRetrievals'
import {objectToArray} from 'Helpers/arrays'
import {toastr} from 'Helpers/toastr'
import {scrollContainerElement} from 'Helpers/utils'
import useIsraeliIdValidator from 'Hooks/useIsraeliIdValidator'
import useNumericDigitsValidator from 'Hooks/useNumericDigitsValidator'
import useRedirect from 'Hooks/useRedirect'
import * as React from 'react'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styles from './EditOrderPanel.module.scss'
import skeletonStyle from 'Components/Skeleton/index.module.scss'

const EditOrderPanel = ({
                          orderId,
                          formValues,
                          setFormValues,
                          cities = [],
                          streets = [],
                          createOrderIsLoading,
                          createOrUpdateOrder,
                          createdOrderInfo,
                          isScrollWanted,
                          setIsScrollWanted,
                          singleOrder,
                          retrieveOrder,
                          retrieveTagWs14,
                          retrieveOrderIsLoading,
                          finalizeOrderWs12,
                          finalizeOrderIsLoading,
                          retrievedTagInfo,
                          activateGponWs15,
                          activateGponIsLoading,
                          retrieveTagIsLoading,
                          createTicketForOrder,
                          createChecklineForOrder,
                          redirectToCreateTicketIsLoading,
                          redirectToCreateChecklineIsLoading,
                          redirectToShowUserTicketsIsLoading,
                          openUserTicketsByOrder,
                          cancelOrderIsLoading,
                          cancelOrder,
                          closeOrderAndRemoveLineIsLoading,
                          closeOrderAndRemoveLine,
                          availableSpeeds,
                          orderStatusDesc,
                        }) => {
  const formRef = useRef()
  const [isCreateOrderSubmitOk, setIsCreateOrderSubmitOk] = useState(false)
  const [handleIsraeliIdChange, israeliIdErrors] = useIsraeliIdValidator(formValues?.israeliId)
  const [handleLineCodeChange, lineCodeErrors] = useNumericDigitsValidator(formValues?.lineCode)
  const [selectedCityRow, setSelectedCityRow] = useState(formValues?.cityRow || emptyCity)
  const [selectedStreetRow, setSelectedStreetRow] = useState(formValues?.streetRow || emptyStreet)
  const [streetsFiltered, setStreetsFiltered] = useState([])
  const [handleContactPhoneChange, contactPhoneErrors] = useNumericDigitsValidator()
  const [isEndSaleModalOpen, setIsEndSaleModalOpen] = useState(false)
  const [bezeqPackage, setBezeqPackage] = useState( {bezeqPackage: 'P1000', downlink: 1000, uplink: 100})
  const [checkboxesValues, setCheckboxesValues] = useState({
    gponApartment: formValues?.gponApartment,
    installToHome: formValues?.installToHome,
    gponTechnician: formValues?.gponTechnician,
    reservedNetwork: formValues?.reservedNetwork,
    mobility: formValues?.mobility,
    }
  )

  const getFormValues = useCallback((_ref = null) => {
    var data = new FormData(_ref?.current || formRef.current)

    let formObject = Object.fromEntries(data.entries())
    Object.keys(formObject).forEach(formKey => {
      if (formObject[formKey] === 'true@@@') {
        formObject[formKey] = true
      }
    })
    formObject.cityRow = selectedCityRow
    formObject.streetRow = selectedStreetRow
    return {...formObject, ...checkboxesValues}
  }, [selectedCityRow, selectedStreetRow, checkboxesValues])


  const saveFormValues = () => {
    const formObject = getFormValues()
    console.log('gizim', 8437270, {formObject})
    setFormValues(formObject)
  }

  const createUpdateOrderFormSubmit = (e) => {
    e.preventDefault()
    saveFormValues()
    createOrUpdateOrder(redirect, orderId)
  }

  useEffect(() => {
    const streetsFiltered = streets?.filter((st) => st.cityId === selectedCityRow?.cityId).concat([emptyStreet])
    setStreetsFiltered(streetsFiltered)
  }, [selectedCityRow, streets])

  useEffect(() => {
    console.log('gizim', 6378220, 'invalidating package and speeds',{availableSpeeds, formValues});

    const currentPackage = availableSpeeds.find(item => item?.uplink === formValues?.uplink && item?.downlink === formValues?.downlink)
    if (currentPackage) {
        console.log('found package to set', 8431270, {currentPackage})
        setBezeqPackage(currentPackage)
    }
  }, [availableSpeeds, formValues, formValues?.downlink, formValues?.uplink])

  const validationObject = {}

  function monitor(formVar) { // runs inside render cycle
    const key = Object.keys(formVar)[0]
    const value = formVar[key]
    validationObject[key] = !!value
    return !!value
  }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    setIsCreateOrderSubmitOk(!atLeastOneError)
  })
  useEffect(() => {
    if (createdOrderInfo && isScrollWanted) {
      scrollContainerElement(skeletonStyle.container)
      setIsScrollWanted(false)
    }
  }, [createdOrderInfo, isScrollWanted, setIsScrollWanted])

  const redirect = useRedirect()

  const openTicket = () => {
    createTicketForOrder(redirect, orderId, {
      cityRow: selectedCityRow,
      streetRow: selectedStreetRow,
      lineCode: formValues?.lineCode,
      houseNum: formValues?.houseNum,
      houseLetter: formValues?.houseLetter,
      apartment: formValues?.apartment,
      floor: formValues?.floor,
      contactPhoneNumber: formValues?.contactPhone,
      contactName: formValues?.firstName + ' ' + formValues?.lastName,
    })
  }

  const openUserTickets = () => {
    openUserTicketsByOrder(redirect, orderId)
  }
  const openCheckLine = () => {
    createChecklineForOrder(redirect, orderId)
  }


  const retrieveTag = () => {
    saveFormValues()
    retrieveTagWs14({orderId})
  }
  const activateGpon = () => {
    const formValues = getFormValues()
    saveFormValues()

    const {
      serialNumber,
      fdhCustomerPortNum,
      fdhId,
      fdhTag,
      fdtTag,
      fdtFloor,
      fdtLocationCode,
      fdtStreetCode,
      fdtHouseNum,
      fdtLetter,
      apartment,
    } = formValues
    if (!serialNumber) {
      toastr.error('יש להגדיר מספר סריאלי לצורך איקטוב')
    } else {
      saveFormValues()

      activateGponWs15({
          orderId,
          serialNumber,
          fdhCustomerPortNum,
          fdhId,
          fdhTag,
          fdtTag,
          fdtFloor,
          fdtLocationCode,
          fdtStreetCode,
          fdtHouseNum,
          fdtLetter,
          apartmentNumber: apartment,
          isClosedOrder: orderStatusDesc.includes('סגורה')
        }
      )
    }
  }
  const isFormDisabled = useMemo(() => orderId > 0, [orderId])
  const mobility = (typeof formValues?.mobility !== 'undefined' && formValues?.mobility)
  if (!cities?.length > 1 || !streets?.length > 1) {
    return <div>ממתין לטעינת ערים ורחובות</div>
  }
  return <div className={classes(styles.orderEditorContainer)}>
    <Paper elevation={3}>
      <Mirror>
        <form ref={formRef} onSubmit={createUpdateOrderFormSubmit}>
          <div className={styles.inARow}>
            <TextField label="תעודת זהות"
                       type="number"
                       name="israeliId"
                       onChange={handleIsraeliIdChange}
                       error={monitor({israeliIdErrors})}
                       helperText={israeliIdErrors}
                       defaultValue={formValues?.israeliId}
                       inputProps={{readOnly: isFormDisabled}}
            />
            <TextField label="קוד קו"
                       type="string"
                       name="lineCode"
                       onChange={handleLineCodeChange}
                       error={monitor({lineCodeErrors})}
                       helperText={lineCodeErrors}
                       defaultValue={formValues?.lineCode}
                       inputProps={{readOnly: isFormDisabled, maxLength: 10}}

            />
          </div>
          <div className={styles.inARow}>
            <TextField label="שם פרטי"
                       name="firstName"
                       defaultValue={formValues?.firstName}
                       inputProps={{readOnly: isFormDisabled}}
            />
            <TextField label="שם משפחה"
                       name="lastName"
                       defaultValue={formValues?.lastName}
                       inputProps={{readOnly: isFormDisabled}}
            />
          </div>
          <div className={styles.inARow}>
            <Autocomplete
              disablePortal
              label="שם יישוב"
              name="cityRow"
              value={selectedCityRow}
              isOptionEqualToValue={(option, value) => {
                return option.cityId === value.cityId
              }}
              onChange={(e, value) => {
                setSelectedStreetRow(emptyStreet);
                setSelectedCityRow(value)
              }}
              options={cities.concat([emptyCity])}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} label="עיר"/>}
              disabled={isFormDisabled}
            />
            <Autocomplete
              disablePortal
              label="שם רחוב"
              name="streetRow"
              value={selectedStreetRow}
              isOptionEqualToValue={(option, value) => {
                return option.streetId === value.streetId
              }}
              onChange={(e, value) => setSelectedStreetRow(value)}
              options={streetsFiltered}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} label="רחוב"/>}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.streetId + option.cityId}>
                    {option.name}
                  </li>
                );
              }}
              disabled={isFormDisabled}
            />
          </div>
          <div className={styles.inARow}>
            <TextField label="מספר בית"
                       type="number"
                       name="houseNum"
                       defaultValue={formValues?.houseNum}
                       inputProps={{readOnly: isFormDisabled}}
            />
            <TextField label="אות"
                       name="houseLetter"
                       defaultValue={formValues?.houseLetter}
                       inputProps={{readOnly: isFormDisabled}}
            />
            <TextField label="כניסה"
                       name="entrance"
                       defaultValue={formValues?.entrance}
                       inputProps={{readOnly: isFormDisabled, maxLength: 2}}
            />
            <TextField label="קומה"
                       name="floor"
                       defaultValue={formValues?.floor}
                       inputProps={{readOnly: isFormDisabled, maxLength: 2}}
            />
            <TextField label="דירה"
                       name="apartment"
                       defaultValue={formValues?.apartment}
                       inputProps={{readOnly: isFormDisabled}}
            />
          </div>
          <div className={styles.inARow}>
            <TextField label="טלפון לתאום"
                       type="string"
                       name="contactPhone"
                       onChange={handleContactPhoneChange}
                       error={monitor({contactPhoneErrors})}
                       helperText={contactPhoneErrors}
                       defaultValue={formValues?.contactPhone}
                       inputProps={{readOnly: isFormDisabled, maxLength: 10}}
            />
            <Select placeholder="סוג בניין"
                    name="gponBuildingType"
                    defaultValue={formValues?.gponBuildingType}
                    disabled={isFormDisabled}
            >
              {objectToArray(GPON_BUILDING_TYPE).map(item =>
                (<Option key={item?.value} value={item?.value}>{item?.label}</Option>)
              )}
            </Select>
          </div>

          <div className={styles.inARow}>
            <Select placeholder="חבילת גלישה"
                    name="bezeqPackageCode"
                    value={bezeqPackage?.bezeqPackage}
                    onChange={(e, v) => {
                      const speed = availableSpeeds.find(item => item?.bezeqPackage === v)
                      console.log({speed})
                      setBezeqPackage(speed)
                    }}
            >
              {availableSpeeds.map(item =>
                (<Option key={item?.bezeqPackage} value={item?.bezeqPackage}>{item?.bezeqPackage}</Option>)
              )}
            </Select>
            <TextField label="קצב הורדה"
                       name="downlink"
                       value={bezeqPackage?.downlink}
                       inputProps={{readOnly: true, maxLength: 4}}
            />
            <TextField label="קצב העלאה"
                       name="uplink"
                       value={bezeqPackage?.uplink}
                       inputProps={{readOnly: true, maxLength: 4}}
            />
          </div>

          <div className={styles.inARow}>
            <FormControlLabel label="יש סיב GPON"
                              className={styles.checkBoxStyleAssurer}
                              control={<Checkbox defaultChecked={checkboxesValues?.gponApartment}
                                                 onChange={(e) => {
                                                   setCheckboxesValues(old => ({
                                                     ...old,
                                                     gponApartment: e.target.checked
                                                   }))
                                                 }}
                                                 name="gponApartment"
                                                 inputProps={{value: "true@@@", disabled: isFormDisabled}}
                              />}
                              // inputProps={{readOnly: isFormDisabled}}
            />
            <FormControlLabel label="טכנאי עד הבית"
                              className={styles.checkBoxStyleAssurer}
                              control={<Checkbox defaultChecked={checkboxesValues?.installToHome}
                                                 onChange={(e) => {
                                                   setCheckboxesValues(old => ({
                                                     ...old,
                                                     installToHome: e.target.checked
                                                   }))
                                                 }}
                                                 name="installToHome"
                                                 inputProps={{value: "true@@@", disabled: isFormDisabled}}
                              />}
            />
            <FormControlLabel label="טכנאי GPON"
                              className={styles.checkBoxStyleAssurer}
                              control={<Checkbox defaultChecked={checkboxesValues?.gponTechnician}
                                                 onChange={(e) => {
                                                   setCheckboxesValues(old => ({
                                                     ...old,
                                                     gponTechnician: e.target.checked
                                                   }))
                                                 }}
                                                 name="gponTechnician"
                                                 inputProps={{value: "true@@@", disabled: isFormDisabled}}
                              />}
            />
            <FormControlLabel label="התקנה מרחוק"
                              className={styles.checkBoxStyleAssurer}
                              control={<Checkbox defaultChecked={checkboxesValues?.reservedNetwork}
                                                 onChange={(e) => {
                                                   setCheckboxesValues(old => ({
                                                     ...old,
                                                     reservedNetwork: e.target.checked
                                                   }))
                                                 }}
                                                 name="reservedNetwork"
                                                 inputProps={{value: "true@@@", disabled: isFormDisabled}}
                              />}
                              // inputProps={{readOnly: isFormDisabled}}
            />
            <FormControlLabel label="ניוד"
                              className={styles.checkBoxStyleAssurer}
                              control={<Checkbox defaultChecked={checkboxesValues?.mobility}
                                                 onChange={(e) => {
                                                   setCheckboxesValues(old => ({
                                                     ...old,
                                                     mobility: e.target.checked
                                                   }))
                                                 }}
                                                 name="mobility"
                                                 inputProps={{value: "true@@@", disabled: true}}
                              />}
            />
          </div>
          <div className={styles.inARow}>
            <TextField label="סריאלי"
                       name="serialNumber"
                       inputProps={{maxLength: 30}}
                       defaultValue={formValues?.serialNumber}
            />
            <TextField label="גרסת קושחה"
                       name="planSoftware"
                       disabled={true}
                       inputProps={{maxLength: 30}}
                       defaultValue={formValues?.planSoftware}
            />
          </div>
          <div className={styles.inARow}>
            <TextField label="fdhCustomerPortNum"
                       name="fdhCustomerPortNum"
                       inputProps={{maxLength: 52}}
                       defaultValue={formValues?.fdhCustomerPortNum || retrievedTagInfo?.fdhCustomerPortNum || 1}
            />
            <TextField label="fdhId"
                       name="fdhId"
                       inputProps={{maxLength: 30}}
                       defaultValue={retrievedTagInfo?.fdhId || formValues?.fdhId}
            />
            <TextField label="fdhTag"
                       name="fdhTag"
                       inputProps={{maxLength: 30}}
                       defaultValue={retrievedTagInfo?.fdhTag || formValues?.fdhTag}
            />
            <TextField label="fdtTag"
                       name="fdtTag"
                       inputProps={{maxLength: 30}}
                       defaultValue={retrievedTagInfo?.fdtTag || formValues?.fdtTag || retrievedTagInfo?.fdhTag}
            />
            <TextField label="fdtFloor"
                       name="fdtFloor"
                       inputProps={{maxLength: 30}}
                       defaultValue={formValues?.floor}
            />
          </div>
          <div className={styles.inARow}>
            <TextField label="fdtLocationCode"
                       name="fdtLocationCode"
                       inputProps={{maxLength: 30}}
                       defaultValue={formValues?.fdtLocationCode}
            />
            <TextField label="fdtStreetCode"
                       name="fdtStreetCode"
                       inputProps={{maxLength: 30}}
                       defaultValue={formValues?.fdtStreetCode}
            />
            <TextField label="fdtHouseNum"
                       name="fdtHouseNum"
                       inputProps={{maxLength: 30}}
                       defaultValue={retrievedTagInfo?.houseNum || formValues?.fdtHouseNum}
            />
            <TextField label="fdtLetter"
                       name="fdtLetter"
                       inputProps={{maxLength: 30}}
                       defaultValue={retrievedTagInfo?.letter || formValues?.fdtLetter}
            />
          </div>

          <TextField
            label="הערות ספק לטכנאי לא מתואם"
            name="providerComments"
            multiline
            rows={2}
            defaultValue={formValues?.providerComments}
            disabled={isFormDisabled}
          />
          <div className={styles.orderButtons}>
            {orderId && <SpinnerButton
              onClick={() => retrieveOrder(orderId)}
              disabled={retrieveOrderIsLoading}
              isLoading={retrieveOrderIsLoading}
              startIcon={<UpdateIcon/>}
            >אחזור סטטוס
            </SpinnerButton>}
            {orderId && <SpinnerButton
              onClick={() => retrieveTag(orderId)}
              disabled={retrieveOrderIsLoading}
              isLoading={retrieveTagIsLoading}
              startIcon={<UpdateIcon/>}
            >אחזור תג
            </SpinnerButton>}
            {orderId && <SpinnerButton
              isLoading={redirectToCreateTicketIsLoading}
              onClick={openTicket}
              startIcon={<SupportIcon/>}
            >
              פתיחת תקלה
            </SpinnerButton>}
            {orderId && <SpinnerButton
              isLoading={redirectToShowUserTicketsIsLoading}
              onClick={openUserTickets}
              startIcon={<SupportIcon/>}
            >
              רשימת תקלות
            </SpinnerButton>}
            {orderId && <SpinnerButton
              isLoading={redirectToCreateChecklineIsLoading}
              onClick={openCheckLine}
              startIcon={<TroubleshootIcon/>}
            >
              בדיקת קו
            </SpinnerButton>}
            {orderId && <SpinnerButton
              variant={"contained"}
              color={"secondary"}
              onClick={() => cancelOrder(orderId)}
              isLoading={cancelOrderIsLoading}
              startIcon={<DeleteIcon/>}
            >ביטול הזמנה
            </SpinnerButton>}
            {orderId && <SpinnerButton
              variant={"contained"}
              color={"secondary"}
              onClick={() => closeOrderAndRemoveLine(orderId)}
              isLoading={closeOrderAndRemoveLineIsLoading}
              startIcon={<DeleteIcon/>}
            >ניתוק והסרת הקו
            </SpinnerButton>}
            {orderId && <SpinnerButton
              variant={"contained"}
              color={"primary"}
              onClick={activateGpon}
              isLoading={activateGponIsLoading}
              startIcon={<ActivateIcon/>}
            >
              איקטוב
            </SpinnerButton>}
            {orderId && <BasicModal
              isOpen={finalizeOrderIsLoading || isEndSaleModalOpen}
              setIsOpen={setIsEndSaleModalOpen}
              buttonProps={{variant: 'contained', color: 'secondary', startIcon: <FinishSaleIcon/>}}
              openModalButtonText={'סיום מכירה'}
              title={'סיום מכירה'}
              content={<div>הספק מאשר בשם הלקוח שההעברה בוצעה בידיעת הלקוח ובהתאם לבקשתו</div>}
              footer={<div className={styles.signatureDiv}><SpinnerButton disabled={finalizeOrderIsLoading}
                                                                          onClick={() => finalizeOrderWs12(orderId)}
                                                                          isLoading={finalizeOrderIsLoading} color='secondary'
                                                                          type="button"
                                                                          startIcon={<SignatureIcon/>}
              >חתימה</SpinnerButton></div>}
            />}

            <SpinnerButton disabled={createOrderIsLoading || !isCreateOrderSubmitOk}
                                        isLoading={createOrderIsLoading}
                                        type="submit"
                                        startIcon={<CreateIcon/>}
            >{orderId ? `עדכון הזמנה` : `יצירת הזמנה`}</SpinnerButton>
          </div>
        </form>
      </Mirror>
      {singleOrder && <OrderRetrievals singleOrder={singleOrder}/>}
      {retrievedTagInfo && <RetrievedTagInfo retrievedTagInfo={retrievedTagInfo}/>}
    </Paper>
  </div>
}

export default EditOrderPanel
