import OrdersTable from 'Components/Orders/OrdersTable/OrdersTable'
import {loadOrders} from 'Containers/Orders/actions'
import {getLoadOrdersIsLoading, getOrdersKeyword, getOrdersTable} from 'Containers/Orders/selectors'
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  ordersTable: getOrdersTable(state),
  loadOrdersIsLoading: getLoadOrdersIsLoading(state),
  ordersKeyword: getOrdersKeyword(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadOrders: (offset, limit, keyword) => dispatch(loadOrders(offset, limit, keyword)),
})


export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable)
