import React from 'react';
import styles from './styles.module.scss';
import classes from 'classnames';

const Toastr = ({toastProps, message}) => {
    const {type} = toastProps;
    return (
        <div className={classes(styles.toastr, styles[type])}>
            <div className={styles.title}>{message}</div>
        </div>
    );
};

export default Toastr;
