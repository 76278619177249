import {useState} from 'react'

export default function useRequiredValidator() {
  const [requiredErrors, setRequiredErrors] = useState('')
  const [myValue, setMyValue] = useState()
  const handleAndValidateRequired = ({target:{value}},val) => {
    const requiredErrors = (!value && `שדה זה הינו חובה`)
    setRequiredErrors(requiredErrors)
    setMyValue(value || val)
  }
  return [handleAndValidateRequired, requiredErrors, myValue]
}
