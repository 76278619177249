import {useState} from 'react'

export default function useITMValidator(isLat = true) {
  const [latLonErrors, setLatLonErrors] = useState('');
  const handleAndValidateLatLon = ({target:{value}}) => {
    const digitError = isNaN(value) || (value && !/^\d*$/.test(value)) ? 'יש להקליד רק מספרים' : ''
    // guid is essential otherwise React will not render, and SUBMIT button will not be
    // enabled when pasting into lat/lon input field
    let latLonError = `guid:${Math.random(1).toString().split('.')[1]}`
    if (!isLat) {
      if (!value) {
        latLonError = 'חובה להגדיר קו רוחב'
      } else if (value < 128553) {
        latLonError = 'קו הרוחב קטן מזה של מדינת ישראל'
      } else if (value > 283741) {
        latLonError = 'קו הרוחב גדול מזה של מדינת ישראל'
      }
    } else {
      if (!value) {
        latLonError = 'חובה להגדיר קו אורך'
      } else if (value < 374345) {
        latLonError = 'קו האורך קטן מזה של מדינת ישראל'
      } else if (value > 804642) {
        latLonError = 'קו האורך גדול מזה של מדינת ישראל'
      }
    }
    setLatLonErrors(digitError || latLonError)
  }
  return [handleAndValidateLatLon, latLonErrors]
};
