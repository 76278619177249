export const TENANT_USERS = 'tenantUsers'

export const LOAD_TENANT_USERS = 'LOAD_TENANT_USERS'
export const LOAD_TENANT_USERS_RESULT = 'LOAD_TENANT_USERS_RESULT'
export const LOAD_TENANT_USERS_IS_LOADING = 'loadTenantUsersIsLoading'
export const SET_LOAD_TENANT_USERS_IS_LOADING = 'SET_LOAD_TENANT_USERS_IS_LOADING'

export const UPDATE_TENANT_USER = 'UPDATE_TENANT_USER'
export const DELETE_TENANT_USER = 'DELETE_TENANT_USER'

export const SAVE_TENANT_USERS_IS_LOADING = 'saveTenantUsersIsLoading'
export const SET_SAVE_TENANT_USERS_IS_LOADING = 'SET_SAVE_TENANT_USERS_IS_LOADING'

export const IS_USER_EDITOR_OPEN = 'isUserEditorOpen'
export const SET_IS_USER_EDITOR_OPEN = 'SET_IS_USER_EDITOR_OPEN'
export const USER_BEING_EDITED = 'userBeingEdited'
export const SET_USER_BEING_EDITED = 'SET_USER_BEING_EDITED'

export const DUMMY_PASSWORD = '********'

export const UPLOAD_ADDRESSES_XLSX = 'UPLOAD_ADDRESSES_XLSX'
export const SERVER_PROGRESS = 'serverProgress'
export const PUBLISH_CHUNK = 'PUBLISH_CHUNK'
export const RESET_PROGRESS = 'RESET_PROGRESS'
