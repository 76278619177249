import apiServer, {apiMockupServer} from 'Api/Backend'
import {
  loadTicketsResult, loadSingleTicketResult,
  retrieveTicketWs7Result,
  setCreateTicketIsLoading,
  setEditTicketFormValues,
  setLoadTicketsIsLoading,
  setLoadSingleTicketIsLoading,
  setRetrieveTicketIsLoading,
} from 'Containers/Tickets/actions'
import {CREATE_TICKET_WS8, LOAD_TICKETS, LOAD_SINGLE_TICKET, RETRIEVE_TICKET_WS7} from 'Containers/Tickets/consts'
import {getCreateTicketFormValues} from 'Containers/Tickets/selectors'
import {toastr} from 'Helpers/toastr'
import {formatBackendError, sleep} from 'Helpers/utils'
import {takeEvery, takeLeading, call, put, select} from 'redux-saga/effects'

function* createTicketWs8Saga({redirect, formValues}) {
  yield put(setCreateTicketIsLoading(true))
  try {
    const formData = yield select(getCreateTicketFormValues)
    console.log('gizim', 3810061, {formValues, formData});
    // const result = yield call(apiMockupServer.post, 'CreateTicket', formData)
    // yield call(sleep, 2000)
    const payload = {
      "userName": "shmulik.levy@gmail.com",
      "orderId": formData?.orderId,
      "followUpId": 16,
      "repeatedFault": false,
      "providerComments": formData?.providerComments,
    }
    const result = yield call(apiServer.post, 'Fault/Open', payload)
    redirect(`/tickets/${result?.faultId}`)
  } catch (e) {
    console.error(e)
  }
  yield put(setCreateTicketIsLoading(false))
}

function adjustBackendFault(fault) {
    fault.providerComments = fault.providerComments || fault.providerCommants
    fault.repeatedFault = fault.repeatedFault || fault.repetedFault
    fault.serviceOrderNum = fault.serviceOrderNum || fault.serviceOrderNumber
    fault.cityRow = {cityId: fault?.cityCode, name: fault?.cityName}
    fault.streetRow = {cityId: fault?.cityCode, streetId: fault?.streetCode, name: fault?.streetName}
    fault.streetId = fault.streetCode && Number(fault.streetCode)
    fault.serviceOrderTypeCode = fault.serviceOrderTypeCode && Number(fault.serviceOrderTypeCode)
    return fault
}

function* loadTicketsSaga({limit, offset, keyword, orderId}) {
  console.log('load tickets saga', {limit, offset, keyword, orderId})
  yield put(setLoadTicketsIsLoading(true))
  try {
    // const result = yield call(apiMockupServer.get, 'LoadTickets', {params: {limit,  offset, keyword}})
    // yield call(sleep, 1000)
    const apiResult = yield call(apiServer.get, `Fault/History`, {params: {orderId}})
    const tickets = {}
    apiResult?.faults?.forEach((fault, index) => {
      tickets[fault.faultId] = adjustBackendFault(fault)
    })
    const result = {
      tickets,
      hasMore: false
    }
    yield put(loadTicketsResult(result))
  } catch (e) {
    console.error(e)
  }
  yield put(setLoadTicketsIsLoading(false))
}

function* loadSingleTicketSaga({ticketId}) {
  yield put(setLoadSingleTicketIsLoading(true))
  try {
    const result = yield call(apiServer.get, 'Fault/History', {params: {faultId:ticketId}})
    // const result = yield call(apiMockupServer.get, 'LoadTicket', {params: {ticketId}})
    const ticketData = adjustBackendFault(result?.faults?.[0] || {})
    yield put(loadSingleTicketResult(ticketData))
    yield put(setEditTicketFormValues(ticketData))
  } catch (e) {
    console.error(e)
  }
  yield put(setLoadSingleTicketIsLoading(false))
}

function* retrieveTicketWs7Saga({ticketId}) {
  yield put(setRetrieveTicketIsLoading(true))
  try {
    const result = yield call(apiMockupServer.put, 'RetrieveTicket', {ticketId: Number(ticketId)})
    yield call(sleep, 2000)
    yield put(retrieveTicketWs7Result(result))
  } catch (e) {
    console.error(e)
    toastr.error('שגיאה באחזור סטטוס תקלה');
  }
  yield put(setRetrieveTicketIsLoading(false))
}

export default function* TicketsSaga() {
  yield takeEvery(CREATE_TICKET_WS8, createTicketWs8Saga)
  yield takeLeading(LOAD_TICKETS, loadTicketsSaga)
  yield takeEvery(LOAD_SINGLE_TICKET, loadSingleTicketSaga)
  yield takeEvery(RETRIEVE_TICKET_WS7, retrieveTicketWs7Saga)
}
