import Swagger from "Components/Swagger"
import {connect} from 'react-redux';
import {getIsConnected, getLoggedInUser} from 'Containers/Auth/selectors'

const mapStateToProps =(state, ) =>  {
  const isConnected =  getIsConnected(state)
  const loggedInUser = getLoggedInUser(state)
  return {
    isConnected,
    loggedInUser,
  }
};

export default connect(mapStateToProps, null)(Swagger)
