import {getIsMainLoading} from 'Containers/AppGeneral/selectors'
import {getTenant, getTenantUrlFromUrl} from 'Helpers/tenants'
import React from "react";
import {connect} from 'react-redux';
import {Redirect, Route} from "react-router-dom";
import {getIsConnected} from 'Containers/Auth/selectors'
import UnauthorizedBox from './UnauthorizedBox'

const Empty = () => <></>

function PrivateRoute({isConnected, isMainLoading, computedMatch, location, children, Component = Empty, componentProps = {}, condition = true, ...rest}) {
  let correctUrlByConnection
  let tenantUrlFromUrl
  if (isConnected) {
    const tenant = getTenant()
    if (tenant && computedMatch?.params?.tenantId !== tenant.url.substr(1) && location.pathname !== '/swagger' && location.pathname !== tenant.url) {
      correctUrlByConnection = tenant.url
    }
  } else {
    tenantUrlFromUrl = getTenantUrlFromUrl()
  }
  return (
    <Route
      computedMatch={computedMatch}
      {...rest}
      render={({location, match}) => {
        return isConnected ?
          (correctUrlByConnection ? <Redirect
              to={{
                pathname: correctUrlByConnection,
                state: {from: location}
              }}
            /> :
            children || <Component {...componentProps} location={location} match={match}/>
          ): !isMainLoading && (tenantUrlFromUrl ? <Redirect to={{pathname: tenantUrlFromUrl, state: {from:location} }} /> : <UnauthorizedBox/>)
      }}
    />
  );
}

const mapStateToProps = (state,) => {
  return {
    isConnected: getIsConnected(state),
    isMainLoading: getIsMainLoading(state),
  }
};

export default connect(mapStateToProps, null)(PrivateRoute);
