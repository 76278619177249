import {isEndpointOK} from 'Helpers/netBlockageDetector'
import {AIRGAP_AUTH} from 'Redux/consts'

let backend = ''
let backendResolved = false

// we need resolveBackend() to be synced, so it blocks network access until it resolves
async function resolveBackend() {
    if (backend) {
        return backend
    }
    const dev_backend = process.env.REACT_APP_BACKEND
    if (dev_backend) {
        backendResolved = true
        backend = dev_backend
        console.log('backend from env', {backend})
        return backend
    }
    if (window.location.port === '9443') {
        backend = 'https://localhost:9443'
    } else {
        const currentDomain = window.location.hostname.split('.')[0]
        switch (currentDomain) {
            case 'localhost':
                backend = 'https://ferret-web.geocell.systems'
                break
            case '127.0.0.1':
                backend = 'https://ferret-web.geocell.systems'
                break
            case 'bez-dev':
                backend = 'https://squirrel.geocell.systems'
                break
            case 'bez-test':
                backend = 'https://ferret-web.geocell.systems'
                break
            case 'bez':
                backend = 'https://adonis-web.geocell.systems'
                break
            case 'bezeq':
                backend = 'https://bezeq.kma.net.il'
                break
            case 'smarten':
                backend = 'https://smarten.geocell.systems'
                break
            case 'bezeqtest':
                backend = 'https://bezeqtest.kma.net.il'
                break
            default:
                throw `could not resolve which backend to use for ${window.location.hostname}`
        }
    }
    console.log('initial backend',{backend})

    if (backend === 'https://adonis-web.geocell.systems') {
        const isOk = await isEndpointOK('https://adonis-web.geocell.systems/api/userInfo')
        if (!isOk) {
            backend = 'https://adonis.geocell.systems'
        }
    }
    if (backend === 'https://ferret-web.geocell.systems') {
        const isOk = await isEndpointOK('https://ferret-web.geocell.systems/api/userInfo')
        if (!isOk) {
            backend = 'https://ferret.geocell.systems'
        }
    }
    backendResolved = true
    return backend
}

resolveBackend().catch(console.error);

(async () => {
    while (!backendResolved) {
        // Await is used inside an async function, so it's valid here
        await new Promise(resolve => setTimeout(resolve, 100));
    }
    console.log('backend resolved to', backend)
})();


const getConfig = () => ({
    googleClientId: '',
    firebaseConfig: {
        apiKey: "AIzaSyCsUZ1CwJmaSMGjj3q5GtcEZjrdxEtgyj0",
        authDomain: "geobez-93b3a.firebaseapp.com",
        projectId: "geobez-93b3a",
        storageBucket: "geobez-93b3a.appspot.com",
        messagingSenderId: "375437298311",
        appId: "1:375437298311:web:012bda41b93da3a5ce0622"
    },
    backendServer: backend,
    getApiServer: () => `${backend}/api/`,
    getSwaggerServer: () => console.log(`swagger server is ${backend}/swagger/`) || `${backend}/swagger/`,
    getApiMockupServer: () => `${backend}/api/mockup/`,
    // authorizationMethod: FIREBASE_AUTH,
    authorizationMethod: AIRGAP_AUTH,
    isProduction: backend.startsWith('https://adonis') || backend.startsWith('https://bezeq.kma.net.il') || backend.startsWith('https://smarten'),
    hasSwagger: !backend.startsWith('https://bezeq.kma.net.il'),
    providerEmail: backend.startsWith('https://bezeq.kma.net.il') ? 'mohammad@kma.net.il' : 'dubi@geocell-systems.co.il',
})

export const redirectToProviderIfNeeded = async () => {

    const providers = ['kma','smarten']

    providers.forEach(provider => {
        var regex = new RegExp(`(https?:\/\/bezeq\.${provider}\.net\.il|https?:\/\/localhost:3000|https?:\/\/bez-test\.geocell\.systems|https?:\/\/bez\.geocell\.systems)?\/$`);
        if (regex.test(window.location.href)) {
            console.log('redirecting from ', window.location.href, ` to /${provider}`)
            window.location.href = `/${provider}`
        }
    })

}

export default getConfig

