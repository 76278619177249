import apiServer from 'Api/Backend'
import {redirectToProviderIfNeeded} from 'Config/config.local'
import {getAvailableSpeedsResult, getCitiesResult, getStreetsResult, setIsLoading} from 'Containers/AppGeneral/actions'
import {takeEvery, call, put} from 'redux-saga/effects';
import {LOAD_ESSENTIAL_STRUCTURES, ON_LOAD} from "./consts";

function *onLoadSaga() {
    yield call(redirectToProviderIfNeeded)
}
function *loadEssentialStructuresSaga() {
    yield put(setIsLoading(true))
    try {
        const cities = yield call(apiServer.get, 'sysTables/cities')
        yield put(getCitiesResult(cities))
        const streets = yield call(apiServer.get, 'sysTables/streets')
        yield put(getStreetsResult(streets))
        const speeds = yield call(apiServer.get, 'Order/GetAllAvailablePackages')
        yield put(getAvailableSpeedsResult(speeds?.allPackages))
    } catch (e) {
        console.error(e)
    }
    yield put(setIsLoading(false))
}

export default function* AppGeneralSaga() {
    yield takeEvery(ON_LOAD, onLoadSaga);
    yield takeEvery(LOAD_ESSENTIAL_STRUCTURES, loadEssentialStructuresSaga);
}
