import {connect} from 'react-redux';
import {Route} from "react-router-dom";
import React from "react";

const Empty = () => <></>

function PublicRoute({children, Component = Empty, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location, match }) =>{
                return children || <Component location={location} match={match}/>
}           }
        />
    );
}

export default connect(null, null)(PublicRoute);
