import LocationSearchIcon from '@mui/icons-material/LocationSearching'
import PrepareOrderIcon from '@mui/icons-material/EditSharp'
import ClearFormIcon from '@mui/icons-material/ClearSharp'
import GlobeIcon from '@mui/icons-material/Public'
import SearchIcon from '@mui/icons-material/Search'
import {Button} from '@mui/material'
import BasicModal from 'Components/Generic/BasicModal'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import Skeleton from 'Components/Skeleton'
import {ORDER_PANEL_DEFAULT_FORM_VALUES} from 'Containers/Orders/consts'
import EditOrderPanel from 'Containers/Orders/EditOrderPanel/EditOrderPanel'
import LocateByGis from 'Containers/Orders/LocateByGis/LocateByGis'
import LocateCustomerInfra from 'Containers/Orders/LocateCustomerInfra/LocateCustomerInfra'
import {useEffect, useMemo, useRef, useState} from 'react'
import styles from './CreateOrder.module.scss'

const CreateOrder = ({
                       locateCustomerInfraIsLoading,
                       locateByGisIsLoading,
                       customerInfraInfo,
                       createOrderFormValues,
                       setCreateOrderFormValues,
                       setLocateCustomerWs1Result,
                       locateByGisFormValues,
                       setLocateByGisFormValues,
                       setLocateByGisWs16Result
                     }) => {

  const submitRef = useRef()
  const submitGisRef = useRef()
  const [isLocateCustomerInfraSubmitOk, setIsLocateCustomerInfraSubmitOk] = useState(false)
  const [isLocateTextualOpen, setIsLocateTextualOpen] = useState(false)
  const [isLocateByGisSubmitOk, setIsLocateByGisSubmitOk] = useState(false)
  const [isLocateGisOpen, setIsLocateGisOpen] = useState(false);
  const [editOrderComponentKey, setEditOrderComponentKey] = useState(0)
  const [locateByGisComponentKey, setLocateByGisComponentKey] = useState(0)

  // clear/reload EditOrderPanel on a new successful infra search
  useMemo(() => {
    setEditOrderComponentKey(prev => prev + 1)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createOrderFormValues, setEditOrderComponentKey])

  // clear/reload LocateByGis on a new successful infra search
  useMemo(() => {
    setLocateByGisComponentKey(prev => prev + 1)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [locateByGisFormValues, setLocateByGisComponentKey])

  useEffect( () => {
    if (locateByGisFormValues?.shouldCloseModal) {
      setLocateByGisFormValues(null)
      setIsLocateGisOpen(false)
    }
  },[locateByGisFormValues, setLocateByGisFormValues])

  const clearForm = () => {
    setCreateOrderFormValues(ORDER_PANEL_DEFAULT_FORM_VALUES)
    setLocateCustomerWs1Result(null)
    setEditOrderComponentKey(prev => prev + 1)
  }

  const clearGisForm = () => {
    setCreateOrderFormValues(ORDER_PANEL_DEFAULT_FORM_VALUES)
    setLocateByGisFormValues(null)
    setLocateByGisWs16Result(null)
    setLocateByGisComponentKey(prev => prev + 1)
  }
  return <Skeleton className={styles.orderPageContainer}>
      <div className={styles.orderHeaderContainer}>
        <h3>הזמנה חדשה</h3>
        <div className={styles.topButtonsPanel}>
          <BasicModal
            isOpen={isLocateTextualOpen}
            setIsOpen={setIsLocateTextualOpen}
            hideBackdrop={true}
            openModalButtonText={'איתור לקוח ותשתית'}
            buttonProps={{variant: 'outlined', color: 'secondary', startIcon: <SearchIcon/>}}
            title={'איתור לקוח ותשתית'}
            content={<LocateCustomerInfra submitRef={submitRef} isLocateCustomerInfraSubmitOk={isLocateCustomerInfraSubmitOk} setIsLocateCustomerInfraSubmitOk={setIsLocateCustomerInfraSubmitOk}/>}
            footer={<div className={styles.footer}>

              <SpinnerButton disabled={!isLocateCustomerInfraSubmitOk}
                             onClick={() => submitRef.current.click()}
                             isLoading={locateCustomerInfraIsLoading}
                             startIcon={<SearchIcon/>}
              >איתור</SpinnerButton>
              <Button disabled={!(customerInfraInfo?.messageCode <= 2)}
                      onClick={() => setIsLocateTextualOpen(false)}
                      variant="contained"
                      color="primary"
                      type="button"
                      startIcon={<PrepareOrderIcon/>}
              >המשך להכנת ההזמנה</Button>
            </div>}
          />
          <BasicModal
            isOpen={isLocateGisOpen}
            setIsOpen={setIsLocateGisOpen}
            hideBackdrop={true}
            openModalButtonText={'איתור גאוגרפי'}
            buttonProps={{variant: 'outlined', color: 'secondary', startIcon: <GlobeIcon/>}}
            title={'איתור גאוגרפי'}
            key={locateByGisComponentKey}
            content={<LocateByGis submitGisRef={submitGisRef} isLocateByGisSubmitOk={isLocateByGisSubmitOk} setIsLocateByGisSubmitOk={setIsLocateByGisSubmitOk}/>}
            footer={<div className={styles.footerGis}>
              <div className={styles.smallButtons}>
                <SpinnerButton disabled={!isLocateByGisSubmitOk}
                               className={styles.locateButton}
                               onClick={() => submitGisRef.current.click()}
                               isLoading={locateByGisIsLoading}
                               startIcon={<LocationSearchIcon/>}
                >איתור</SpinnerButton>
                <Button className={styles.clearFormButton}
                        color="primary" onClick={clearGisForm} startIcon={<ClearFormIcon/>}>נקה טופס</Button>
              </div>

              <Button disabled={!locateByGisFormValues}
                      onClick={() => setIsLocateGisOpen(false)}
                      variant="contained"
                      color="primary"
                      className={styles.continueButton}
                      type="button"
                      startIcon={<PrepareOrderIcon/>}
              >המשך להכנת ההזמנה</Button>
            </div>}
          />
          <Button color="primary" onClick={clearForm} startIcon={<ClearFormIcon/>}>נקה טופס</Button>
        </div>
      </div>
    <EditOrderPanel key={`editOrder${editOrderComponentKey}`} formValues={createOrderFormValues} setFormValues={setCreateOrderFormValues}/>
  </Skeleton>
}

export default CreateOrder
