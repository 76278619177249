import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import {FormControlLabel, Radio, RadioGroup, TextField} from '@mui/material'
import Button from '@mui/material/Button'
import classes from 'classnames'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import {DUMMY_PASSWORD} from 'Containers/AdminConsole/consts'
import styles from './UserEditor.module.scss'
import useEmailValidator from 'Hooks/useEmailValidator'
import useRequiredValidator from 'Hooks/useRequiredValidator'
import React, {useEffect, useRef, useState} from 'react'

export default function UserEditor({userBeingEdited, updateTenantUser, deleteTenantUser, saveTenantUsersIsLoading, setIsUserEditorOpen, loggedInUser}) {
  const [handleEmailChange, emailErrors] = useEmailValidator()
  const [handlePasswordChange, passwordErrors] = useRequiredValidator()
  const [handleDisplayNameChange, displayNameErrors] = useRequiredValidator()
  const [isSubmitOk, setIsSubmitOk] = useState(false)
  const validationObject = {}
  const [confirmMode, setConfirmMode] = useState(false)
  const formRef = useRef()

  function monitor(validatorStateKeyVal) { // runs inside render cycle
    const key = Object.keys(validatorStateKeyVal)[0]
    const value = validatorStateKeyVal[key]
    validationObject[key] = !!value
    return !!value
  }

  const getFormValues = () => {
    var data = new FormData(formRef.current)
    let formObject = Object.fromEntries(data.entries())
    Object.keys(formObject).forEach(formKey => {
      if (formObject[formKey] === 'true@@@') {
        formObject[formKey] = true
      }
    })
    return formObject
  }

  useEffect(() => {
    if (confirmMode?.transition === 'toConfirm') {
      const {transition, ...newConfirmMode} = confirmMode
      setConfirmMode(newConfirmMode)
    } else if (confirmMode?.transition === 'toActions') {
      setConfirmMode(false)
    }
  }, [confirmMode])

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    const formValues = getFormValues()
    const areValuesMissing = !formValues.email || !formValues.displayName || !formValues.password
    setIsSubmitOk(!atLeastOneError && !areValuesMissing)
  })

  const _updateTenantUser = () => {
    const formValues = getFormValues()
    updateTenantUser(formValues.email, {
      displayName: formValues.displayName,
      claims: {geobez: formValues?.geobez},
      // dont pass a dummy password nor a password which user pressed CTRL-Z after typing
      ...(formValues.password && formValues.password !== DUMMY_PASSWORD && ({password: formValues.password}))
    })
  }
  const _deleteTenantUser = () => {
    const formValues = getFormValues()
    deleteTenantUser(formValues.email)
  }
  return (
    <div className={styles.container}>
      <form className={styles.form} ref={formRef} onSubmit={(e) => {
        e.preventDefault()
      }}>
        <TextField
          required
          name="email"
          label="Email"
          variant="standard"
          onChange={handleEmailChange}
          error={monitor({emailErrors})}
          helperText={emailErrors}
          defaultValue={userBeingEdited?.[0]}
          inputProps={userBeingEdited ? {readOnly: true} : undefined}
        />
        <TextField
          required
          name="displayName"
          label="Display Name"
          variant="standard"
          onChange={handleDisplayNameChange}
          error={monitor({displayNameErrors})}
          helperText={displayNameErrors}
          defaultValue={userBeingEdited?.[1].displayName}
        />
        <TextField
          required={!userBeingEdited}
          name="password"
          label="Password"
          type="password"
          variant="standard"
          onChange={handlePasswordChange}
          error={monitor({passwordErrors})}
          helperText={passwordErrors}
          defaultValue={userBeingEdited ? DUMMY_PASSWORD : ''}
        />
        <RadioGroup
          defaultValue={userBeingEdited?.[1].claims?.geobez?.toLowerCase() || 'user'}
          name="geobez"
          className={styles.oneRow}
        >
          <FormControlLabel label="User"
                            value="user"
                            className={styles.checkBoxStyleAssurer}
                            control={<Radio/>}
          />
          <FormControlLabel label="Admin"
                            value="admin"
                            className={styles.checkBoxStyleAssurer}
                            control={<Radio/>}
          />
          {loggedInUser.geobez.toLowerCase() === 'god' && <FormControlLabel label="God"
                            value="god"
                            className={styles.checkBoxStyleAssurer}
                            control={<Radio/>}
          />}
        </RadioGroup>
        <button hidden={true}>submit</button>
      </form>
      <div className={styles.footer}>
        <div className={classes({
          [styles.confirmRow]: confirmMode && !confirmMode?.transition,
          [styles.confirmRowStart]: confirmMode && confirmMode?.transition === 'toConfirm',
          [styles.confirmRowHidden]: !confirmMode || confirmMode?.transition === 'toActions',
        })}>
          <span>{confirmMode.message}</span>
          <SpinnerButton
            isLoading={saveTenantUsersIsLoading}
            onClick={confirmMode.onClick}
            variant="contained"
            color={confirmMode.color || "primary"}
            type="button"
          >כן</SpinnerButton>
          <Button
            onClick={() => setConfirmMode({transition: 'toActions', message: confirmMode.message})}
            variant="contained"
            color="primary"
            type="button"
          >לא</Button>
        </div>
        <div className={classes({
          [styles.actionsRow]: !confirmMode,
          [styles.actionsRowStart]: confirmMode && confirmMode?.transition === 'toActions',
          [styles.actionsRowHidden]: confirmMode && confirmMode?.transition !== 'toActions'
        })}>
          <Button
            onClick={() => setConfirmMode({transition: 'toConfirm', message: 'האם לשמור משתמש?', onClick: _updateTenantUser.bind(this)})}
            variant="contained"
            color="primary"
            type="button"
            startIcon={<SaveIcon/>}
            disabled={!isSubmitOk}
          >שמירה</Button>
          <Button onClick={() => setIsUserEditorOpen(false)}
                  variant="contained"
                  color="primary"
                  type="button"
                  startIcon={<CloseIcon/>}
          >ביטול</Button>
          <Button
            onClick={() => setConfirmMode({color: "error", transition: 'toConfirm', message: 'האם למחוק משתמש?', onClick: _deleteTenantUser.bind(this)})}
            variant="contained"
            color="error"
            type="button"
            startIcon={<DeleteIcon/>}
            disabled={!userBeingEdited}
          >מחיקה</Button>
        </div>
      </div>
    </div>
  )
}
