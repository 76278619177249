import {CacheProvider} from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import {prefixer} from "stylis";
import createCache from "@emotion/cache";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin]
});

export default function Mirror({children}) {
  return <CacheProvider value={cacheRtl}>
    {children}
  </CacheProvider>
}
