import modalStyles from 'Components/Generic/BasicModal.module.scss'

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function scrollContainerElement(classToScroll = modalStyles.scrollableContainer) {
  for(const objDiv of Array.from(document.getElementsByClassName(classToScroll))){
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight
  }
}
export function stripGuid(str) {
  const regex = /^guid:.+/;
  return regex.test(str) ? '' : str;
}

export function formatBackendError({  errorDescription = '',
                                      ErrorDescription = '',
                                      messageDesc = '',
                                      MessageDesc = '',
                                   }) {
  // not parsing techMessageDesc because it may return הצלחה on error

  const possibleErrors = [
    (!messageDesc && !MessageDesc) ?
      errorDescription || ErrorDescription || 'תקלת שרת'
      : '',
    messageDesc ? `בזק: ${messageDesc}` : '',
    MessageDesc ? `בזק: ${MessageDesc}` : '',
  ]
  return possibleErrors.reduce((acc, err) => {
    if (err) {
      return `${acc}, ${err}`
    } else {
      return acc
    }
  }, '')
}
