import React from 'react';
import AuthBox from '../../../../Containers/Auth/AuthBox'
import styles from './index.module.scss'
import getConfig from 'Config/config'

const LoginLogoutBox = () => {
    return (
        <div className={styles.loginLogoutBox}>
          <h1>מערכת ניהול הזמנות מתשתית בזק -
          שוק סיטונאי</h1>
          {getConfig().isProduction ? <h2>סביבת ייצור</h2> : <h2>סביבת טסט</h2>}
          <AuthBox/>
        </div>
    );
};

export default LoginLogoutBox;
