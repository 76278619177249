import Tickets from 'Components/Tickets/Tickets'
import {getTicketsKeyword} from 'Containers/Tickets/selectors'
import {setTicketsKeyword} from 'Containers/Tickets/actions'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    setTicketsKeyword: keyword => dispatch(setTicketsKeyword(keyword))
  }
};
const mapStateToProps = (state, ownProps) => {
  const {match:{params:{orderId}}} = ownProps

  return {
    ticketsKeyword: getTicketsKeyword(state),
    orderId,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
